import { Directive, ElementRef, Renderer2, inject } from '@angular/core';
import { takeUntil } from 'rxjs';
import { WdxDestroyClass } from '../../services';
import { IThemeService } from '../../interfaces';

@Directive({
    selector: '[wdxUtilsAgGridTheme]',
    standalone: true,
})
export class WdxAgGridThemeDirective extends WdxDestroyClass {
    private elementRef = inject(ElementRef);
    private renderer = inject(Renderer2);
    private currentThemeService = inject(IThemeService);

    constructor() {
        super();
        this.currentThemeService.currentTheme$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((theme) => {
                if (theme === 'Dark') {
                    this.renderer.removeClass(
                        this.elementRef.nativeElement,
                        'ag-theme-quartz'
                    );
                    this.renderer.addClass(
                        this.elementRef.nativeElement,
                        'ag-theme-quartz-dark'
                    );
                    return;
                }
                this.renderer.removeClass(
                    this.elementRef.nativeElement,
                    'ag-theme-quartz-dark'
                );
                this.renderer.addClass(
                    this.elementRef.nativeElement,
                    'ag-theme-quartz'
                );
            });
    }
}
