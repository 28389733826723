import { Observable } from 'rxjs';
import { FormFrameworkEvent } from '../models';
import { FormStaticService } from '../services';

export abstract class IFormExternalActions {
    public abstract triggerSave(): void;
    public abstract getTriggerSave$(): Observable<void> | undefined;
    public abstract triggerSaveDraft(): void;
    public abstract getTriggerSaveDraft$(): Observable<void> | undefined;

    public abstract cleanup(): void;
    public abstract init(formStaticService: FormStaticService): void;
    public abstract closeSpawnedForms(event?: FormFrameworkEvent): void;
    public abstract handleQuickCreate(): void;
    public abstract handleOpenInNewTab(entity: string, id?: string): void;
}
