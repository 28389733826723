<div class="overflow-hidden flex-grow-1" [wdxIsLoading]="loading">
    <ag-grid-angular
        *ngIf="gridOptions"
        [gridOptions]="gridOptions"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        wdxUtilsAgGridTheme
        class="ag-theme-quartz w-100 h-100"
        (gridReady)="onGridReady($event)"
        (sortChanged)="onSortChanged($event)"
        (rowClicked)="onRowClicked($event)"
    ></ag-grid-angular>
</div>
