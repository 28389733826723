import { Component, Input } from '@angular/core';
import { DIALOG_TITLE_MAP } from '../../../constants/dialog.constants';
import { DialogMode, DialogOptionsData } from '../../../models/dialog.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    TRANSLATION_DIALOG_CANCEL_ACTIVITY,
    TRANSLATION_DIALOG_CANCEL_CASE,
    TRANSLATION_DIALOG_CANCEL_DOC_REQUIREMENT,
    TRANSLATION_DIALOG_DELETE_ACTIVE_CLIENT_ROLE,
    TRANSLATION_DIALOG_DELETE_BATCH_LIST,
    TRANSLATION_DIALOG_DELETE_BATCH_MESSAGE_ROW,
    TRANSLATION_DIALOG_DELETE_DASHBOARD,
    TRANSLATION_DIALOG_DELETE_DOC,
    TRANSLATION_DIALOG_DELETE_DOC_REQUIREMENT,
    TRANSLATION_DIALOG_DELETE_MULTIPLE_MESSAGE,
    TRANSLATION_DIALOG_DELETE_OPPORTUNITY,
    TRANSLATION_DIALOG_GENERAL_DELETE_MESSAGE,
    TRANSLATION_DIALOG_REMOVE_CLIENT_ROLE,
    TRANSLATION_DIALOG_REMOVE_TEAM_MEMBER,
    TRANSLATION_DIALOG_REPLAY_MESSAGE_BATCH,
    TRANSLATION_DIALOG_REPLAY_MULTIPLE_MESSAGE,
    TRANSLATION_DIALOG_RUN_PEP_SANCTIONS_CHECK,
    TRANSLATION_DIALOG_SHARE_APP_WITH_CLIENT,
    TRANSLATION_NO_BTN,
    TRANSLATION_RETURN_APP_TO_CLIENT,
    TRANSLATION_YES_BTN,
} from '@wdx/shared/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'molecule-dialog',
    templateUrl: './molecule-dialog.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: '' },
})
export class MoleculeDialogComponent {
    constructor(private activeModal: NgbActiveModal) {}

    readonly DIALOG_MODE = DialogMode;
    readonly YES_BTN = TRANSLATION_YES_BTN;
    readonly NO_BTN = TRANSLATION_NO_BTN;
    readonly REMOVE_TEAM_MEMBER = TRANSLATION_DIALOG_REMOVE_TEAM_MEMBER;
    readonly CANCEL_ACTIVITY = TRANSLATION_DIALOG_CANCEL_ACTIVITY;
    readonly CANCEL_CASE = TRANSLATION_DIALOG_CANCEL_CASE;
    readonly DELETE_DOC_REQUIREMENT = TRANSLATION_DIALOG_DELETE_DOC_REQUIREMENT;
    readonly CANCEL_DOC_REQUIREMENT = TRANSLATION_DIALOG_CANCEL_DOC_REQUIREMENT;
    readonly RUN_PEP_SANCTIONS_CHECK =
        TRANSLATION_DIALOG_RUN_PEP_SANCTIONS_CHECK;
    readonly DELETE_DASHBOARD = TRANSLATION_DIALOG_DELETE_DASHBOARD;
    readonly GENERAL_DELETE_MESSAGE = TRANSLATION_DIALOG_GENERAL_DELETE_MESSAGE;
    readonly DELETE_BATCH_LIST = TRANSLATION_DIALOG_DELETE_BATCH_LIST;
    readonly DELETE_BATCH_MESSAGE_ROW =
        TRANSLATION_DIALOG_DELETE_BATCH_MESSAGE_ROW;
    readonly REPLAY_MESSAGE_BATCH = TRANSLATION_DIALOG_REPLAY_MESSAGE_BATCH;
    readonly DELETE_VIEW = TRANSLATION_DIALOG_DELETE_BATCH_LIST;
    readonly DELETE_MULTIPLE_MESSAGE =
        TRANSLATION_DIALOG_DELETE_MULTIPLE_MESSAGE;
    readonly REPLAY_MULTIPLE_MESSAGE =
        TRANSLATION_DIALOG_REPLAY_MULTIPLE_MESSAGE;
    readonly DIALOG_DELETE_DOC = TRANSLATION_DIALOG_DELETE_DOC;
    readonly DIALOG_DELETE_OPPORTUNITY = TRANSLATION_DIALOG_DELETE_OPPORTUNITY;
    readonly SHARE_APP_WITH_CLIENT = TRANSLATION_DIALOG_SHARE_APP_WITH_CLIENT;
    readonly TRANSLATION_DIALOG_REMOVE_CLIENT_ROLE =
        TRANSLATION_DIALOG_REMOVE_CLIENT_ROLE;
    readonly DELETE_ACTIVE_CLIENT_ROLE =
        TRANSLATION_DIALOG_DELETE_ACTIVE_CLIENT_ROLE;
    readonly RETURN_APP_TO_CLIENT = TRANSLATION_RETURN_APP_TO_CLIENT;

    @Input() dialogMode: DialogMode;
    @Input() data: DialogOptionsData;

    getTitle(dialogMode: DialogMode): string {
        return DIALOG_TITLE_MAP[dialogMode];
    }

    dismiss(): void {
        this.activeModal.dismiss();
    }

    confirm(): void {
        this.activeModal.close();
    }
}
