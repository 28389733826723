import { Injectable, inject } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WdxDialogService {
    modalRef!: NgbModalRef;
    disableButtons$ = new Subject<boolean>();

    private ngbModal = inject(NgbModal);

    open(content: any, config = {}): NgbModalRef {
        const DEFAULT = {
            backdropClass: 'modal-backdrop--ngb',
            windowClass: 'modal-window--ngb',
            centered: true,
            modalDialogClass: 'modal-dialog-centred-xs',
        };

        this.modalRef = this.ngbModal.open(content, { ...DEFAULT, ...config });

        return this.modalRef;
    }

    close(result?: any): void {
        this.modalRef?.close(result);
    }

    dismiss(): void {
        this.modalRef?.dismiss();
    }
}
