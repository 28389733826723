<div
    container="body"
    [class.pointer]="popupEnabled"
    data-cy="avatar-container"
    popoverClass="popover-no-padding popover-avatar"
    [ngbPopover]="popupEnabled ? avatarPopover : null"
>
    <ng-content select="[wdxAvatar]"></ng-content>
</div>
<ng-template #avatarPopover>
    <ng-content
        data-cy="avatar-container-popup"
        select="[wdxAvatarPopupContent]"
    ></ng-content>
</ng-template>
