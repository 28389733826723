<wdx-avatar wdxAvatar [avatar]="avatar" [size]="'xl'" class="me-2"></wdx-avatar>
<div class="d-flex flex-column">
    <h6>{{ content?.name }}</h6>
    <wdx-badge
        *ngIf="content?.badge?.status"
        [themeColor]="$any(content?.badge?.themeColor)"
        [attr.data-cy]="'avatar-popup-status-badge'"
    >
        {{ content?.badge?.status }}
    </wdx-badge>
    <div *ngIf="content?.info" [attr.data-cy]="'avatar-popup-info'">
        {{ $any(content?.info) | titleCase }}
    </div>
    <ng-container *ngFor="let phoneNumber of content?.phoneNumbers; index as i">
        <a
            [attr.href]="'tel:' + phoneNumber"
            [attr.data-cy]="'avatar-popup-phone-number-' + i"
        >
            {{ phoneNumber }}
        </a>
    </ng-container>
    <ng-container *ngFor="let email of content?.emails; index as i">
        <a
            [attr.href]="'mailto:' + email"
            [attr.data-cy]="'avatar-popup-email-' + i"
        >
            {{ email }}
        </a>
    </ng-container>
</div>
