import { ColDef, GridApi } from '@ag-grid-community/core';
import { delay, firstValueFrom, of, tap } from 'rxjs';

export const UPDATE_COL_DEFS: (
    gridApi: GridApi,
    colDefs: ColDef[]
) => Promise<void> = (gridApi: GridApi, colDefs: ColDef[]) => {
    // we need to set the columnDefs to empty array, then allow time using delay for that apply before setting our desired config
    gridApi.setGridOption('columnDefs', []);
    return firstValueFrom(
        of(undefined).pipe(
            delay(0),
            tap(() => {
                gridApi.setGridOption('columnDefs', colDefs);
                gridApi.refreshCells();
            })
        )
    );
};
