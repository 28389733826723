import { FormDataStatus } from '@wdx/shared/utils';

export enum FormFrameworkEventType {
    Dirty,
    SaveStart,
    SaveFailed,
    SaveClicked,
    SaveSuccess,
    FormLoaded,
    FormInitialised,
    FormValueChanged,
    Touched,
}

export interface FormFrameworkEventAction {
    dirty?: boolean;
    pristine?: boolean;
    touched?: boolean;
    formValue?: Record<string, any>;
    valid?: boolean;
    event?: Event;
    formStatus?: FormDataStatus;
}

export interface FormFrameworkEvent {
    type: FormFrameworkEventType;
    typeName: string;
    action?: FormFrameworkEventAction;
}

export class FormFrameworkFullDataEvent implements FormFrameworkEvent {
    type: FormFrameworkEventType;
    typeName: string;
    action: FormFrameworkEventAction;

    constructor(
        type: FormFrameworkEventType,
        action: FormFrameworkEventAction,
    ) {
        this.type = type;
        this.typeName = FormFrameworkEventType[type];
        this.action = action;
    }
}

export class FormFrameworkFormValueChangedEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.FormValueChanged, action);
    }
}

export class FormFrameworkFormTouchedEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.Touched, action);
    }
}

export class FormFrameworkSaveStartEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.SaveStart, action);
    }
}

export class FormFrameworkSaveFailedEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.SaveFailed, action);
    }
}

export class FormFrameworkSaveSuccessEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.SaveSuccess, action);
    }
}

export class FormFrameworkLoadedEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.FormLoaded, action);
    }
}

export class FormFrameworkInitialisedEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.FormInitialised, action);
    }
}

export class FormFrameworkSaveClickedEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.SaveClicked, action);
    }
}

export class FormFrameworkDirtyEvent extends FormFrameworkFullDataEvent {
    constructor(action: FormFrameworkEventAction) {
        super(FormFrameworkEventType.Dirty, action);
    }
}
