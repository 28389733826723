import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ICellRendererParams } from '@ag-grid-community/core';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';
import { WdxThemeColor } from '@wdx/shared/utils';

export type BadgeRendererMapping = Record<string, WdxThemeColor>;

export interface BadgeRendererValue {
    label: string;
    badgeColorMapping: BadgeRendererMapping;
}

@Component({
    selector: 'wdx-ag-grid-badge-cell-renderer',
    standalone: true,
    imports: [CommonModule, RouterModule, WdxBadgeModule],
    template: '<div [ngClass]="classes">{{ label }}</div>',
})
export class BadgeRendererComponent implements ICellRendererAngularComp {
    public label?: string;
    public classes = 'badge';
    private mapping?: BadgeRendererMapping;

    public agInit(params: ICellRendererParams<any, string>): void {
        if (params?.value) {
            this.label = params.value;
            this.mapping = (params as any).mapping;
            this.setClasses(this.label);
        }
    }

    public refresh(params: ICellRendererParams<any, string>): boolean {
        this.agInit(params);
        return true;
    }

    private setClasses(label?: string) {
        const colorClass =
            (label && this.mapping && this.mapping[label.toLowerCase()]) || '';
        this.classes = colorClass ? `badge badge-${colorClass}` : 'badge';
    }
}
