import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    inject,
} from '@angular/core';

import {
    FieldDefinition,
    FormDataResult,
    FormDataStatus,
    FormDefinition,
    FormElementLayoutDefinition,
    FormFieldType,
    FormSaveMode,
    FormSectionLayoutDefinition,
    SelectApiSource,
    SystemEntity,
    UserActionEntity,
} from '@wdx/clmi/api-models';

import {
    ADDRESS_SUMMARY_TYPES,
    ARRAY_SUMMARY_TYPES,
    AddressFormatService,
    CUSTOM_SUMMARY_TYPES,
    FILE_SUMMARY_TYPES,
    GET_STATUS_PENDING_CHANGES,
    LOOKUP_SUMMARY_TYPES,
    OPTIONS_SUMMARY_TYPES,
    PENDING_CHANGE_LOCK_ALL,
    PHONE_SUMMARY_TYPES,
    RICH_TEXT_SUMMARY_TYPES,
    TAGS_SUMMARY_TYPES,
} from '@wdx/shared/infrastructure/form-framework';
import { KeyValueObject } from '@wdx/shared/utils';
import { Observable, map } from 'rxjs';
import { OpenInNewTabService } from '../../../../../../shared/services';
import { FormSummaryPendingChangesService } from '../../../services/form-pending-changes/form-pending-changes.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'organism-form-summary-content',
    templateUrl: './organism-form-summary-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'd-block',
    },
})
export class OrganismFormSummaryContentComponent
    implements OnInit, AfterContentInit
{
    @Input() entityId: string;
    @Input() data: any;
    @Input() parentData: any;
    @Input() formDetails: FormDataResult;
    @Input() layoutDefinition: FormElementLayoutDefinition;
    @Input() fieldIsRequiredInLayout: KeyValueObject<boolean>;
    @Input() definition: FormDefinition;
    @Input() isSubForm = false;
    @Input() subFormDataIndex: number;
    @Input() subFormData;

    get pendingChangeAction(): string {
        return (GET_STATUS_PENDING_CHANGES as Record<string, string>)[
            this._pendingChangeAction
        ];
    }

    private _pendingChangeAction;

    activeIds: string[];
    saveDraftMode: boolean;
    isDraftForm: boolean;
    showFormSwitcherPanel: boolean;
    fieldIsRequiredInLayoutMap: KeyValueObject<boolean> = {};

    draftForm: FormDataResult;
    publishedForm: FormDataResult;

    readonly ARRAY_SUMMARY_TYPES = ARRAY_SUMMARY_TYPES;
    readonly LOOKUP_SUMMARY_TYPES = LOOKUP_SUMMARY_TYPES;
    readonly TAGS_SUMMARY_TYPES = TAGS_SUMMARY_TYPES;
    readonly PHONE_SUMMARY_TYPES = PHONE_SUMMARY_TYPES;
    readonly FILE_SUMMARY_TYPES = FILE_SUMMARY_TYPES;
    readonly ADDRESS_SUMMARY_TYPES = ADDRESS_SUMMARY_TYPES;
    readonly CUSTOM_SUMMARY_TYPES = CUSTOM_SUMMARY_TYPES;
    readonly RICH_TEXT_SUMMARY_TYPES = RICH_TEXT_SUMMARY_TYPES;
    readonly OPTIONS_SUMMARY_TYPES = OPTIONS_SUMMARY_TYPES;
    readonly SELECT_API_SOURCE = SelectApiSource;
    readonly FORM_FIELD_TYPE = FormFieldType;
    readonly FORM_DATA_STATUS = FormDataStatus;
    readonly PENDING_CHANGE_LOCK_ALL = PENDING_CHANGE_LOCK_ALL;

    public formSummaryPendingChangesService = inject(
        FormSummaryPendingChangesService,
    );
    private ointService = inject(OpenInNewTabService);
    private addressFormatService = inject(AddressFormatService);

    ngOnInit(): void {
        this._pendingChangeAction =
            this.formSummaryPendingChangesService.subFormPendingChanges?.[
                this.layoutDefinition.name
            ]?.[this.subFormDataIndex]?.action;
        this.setDraftModeProps();
        this.setFieldIsRequiredInLayoutMap();

        this.activeIds = this.isSubForm
            ? []
            : Array.from(
                  Array(this.layoutDefinition.sectionLayoutDefinitions.length),
              ).map((_, i) => `panel-${i}`);
    }

    ngAfterContentInit(): void {
        this.showFormVersionSwitcher();
        this.storeFormSwitchData();
    }

    setDraftModeProps(): void {
        this.saveDraftMode = this.definition?.saveModes?.includes(
            FormSaveMode.Draft,
        );
        this.isDraftForm =
            this.formDetails?.status === this.FORM_DATA_STATUS.Draft;
    }

    setFieldIsRequiredInLayoutMap(): void {
        if (this.fieldIsRequiredInLayout) {
            this.fieldIsRequiredInLayoutMap = this.fieldIsRequiredInLayout;
            return;
        }
        if (!this.definition) {
            return;
        }
        const getIsRequired = (
            sectionLayoutDefinitions: FormSectionLayoutDefinition[],
        ) => {
            sectionLayoutDefinitions?.forEach((sectionLayoutDefinition) => {
                sectionLayoutDefinition.elementLayoutDefinitions.forEach(
                    (elementLayoutDefinition) => {
                        this.fieldIsRequiredInLayoutMap = {
                            ...this.fieldIsRequiredInLayoutMap,
                            [elementLayoutDefinition.name]:
                                elementLayoutDefinition.isRequired || false,
                        };
                        if (
                            elementLayoutDefinition.sectionLayoutDefinitions
                                ?.length
                        ) {
                            getIsRequired(
                                elementLayoutDefinition.sectionLayoutDefinitions,
                            );
                        }
                    },
                );
            });
        };
        this.fieldIsRequiredInLayoutMap = {};
        getIsRequired(this.definition.layout.sectionLayoutDefinitions);
    }

    showFormVersionSwitcher(): void {
        this.showFormSwitcherPanel =
            this.saveDraftMode &&
            this.isDraftForm &&
            this.formDetails?.lastPublishedData;
    }

    storeFormSwitchData(): void {
        if (this.formDetails?.status === FormDataStatus.Draft) {
            this.draftForm = this.formDetails;
        }

        if (this.formDetails?.lastPublishedData) {
            this.publishedForm = this.formDetails.lastPublishedData;
        }
    }

    switchFormVersion() {
        const formStatus: FormDataStatus = this.formDetails?.status;

        if (formStatus === FormDataStatus.Draft) {
            this.formDetails = this.publishedForm;
            this.data = this.publishedForm.data;
            this.setDraftModeProps();
        } else if (formStatus === FormDataStatus.Published) {
            this.formDetails = this.draftForm;
            this.data = this.draftForm.data;
            this.setDraftModeProps();
        }
    }

    openLinkInNewTab(entity: string, id?: string): void {
        this.ointService.openInNewTab(
            SystemEntity[entity] || UserActionEntity[entity],
            id,
        );
    }

    trackBySection(index: number, section: FormSectionLayoutDefinition) {
        return section.name;
    }

    trackByElement(index: number, element: FieldDefinition) {
        return element.name;
    }

    getDisplayAddress(data: any): Observable<string> {
        return this.addressFormatService
            .getAddress$(data, true)
            .pipe(map((item) => item.join(', ')));
    }
}
