import { FilterModel, IDateFilterParams } from '@ag-grid-community/core';
import { PerformancePeriod } from '@wdx/shared/utils';
import { DateTime } from 'luxon';

export const DATE_FILTER_CONFIG = [
    {
        label: 'YTD',
        value: PerformancePeriod.YearToDate,
    },
    {
        label: '1Y',
        value: PerformancePeriod.OneYear,
    },
    {
        label: '3Y',
        value: PerformancePeriod.ThreeYear,
    },
    {
        label: '5Y',
        value: PerformancePeriod.FiveYear,
    },
    {
        label: 'All',
        value: PerformancePeriod.SinceInception,
    },
];

export const DATE_FILTER_PARAMS: IDateFilterParams = {
    filterOptions: ['inRange', 'lessThanOrEqual', 'greaterThanOrEqual'],
    maxNumConditions: 1,
};

export function createDatefilterConfigs(): Partial<
    Record<PerformancePeriod, FilterModel>
> {
    const time = ' 00:00:00';
    const startOfYear =
        DateTime.now().set({ day: 1, month: 1 }).toISODate() + time;
    const OneYearAgo = DateTime.now().minus({ years: 1 }).toISODate() + time;
    const ThreeYearsAgo = DateTime.now().minus({ years: 3 }).toISODate() + time;
    const FiveYearsAgo = DateTime.now().minus({ years: 5 }).toISODate() + time;
    return {
        [PerformancePeriod.YearToDate]: {
            date: {
                dateFrom: startOfYear,
                dateTo: null,
                filterType: 'date',
                type: 'greaterThanOrEqual',
            },
        },
        [PerformancePeriod.OneYear]: {
            date: {
                dateFrom: OneYearAgo,
                dateTo: null,
                filterType: 'date',
                type: 'greaterThanOrEqual',
            },
        },
        [PerformancePeriod.ThreeYear]: {
            date: {
                dateFrom: ThreeYearsAgo,
                dateTo: null,
                filterType: 'date',
                type: 'greaterThanOrEqual',
            },
        },
        [PerformancePeriod.FiveYear]: {
            date: {
                dateFrom: FiveYearsAgo,
                dateTo: null,
                filterType: 'date',
                type: 'greaterThanOrEqual',
            },
        },
        [PerformancePeriod.SinceInception]: {
            date: {},
        },
    };
}

export function checkIsPresetDate(
    config: Partial<Record<PerformancePeriod, FilterModel>>,
    currentSelection: FilterModel
): PerformancePeriod | undefined {
    if (!currentSelection?.['date']) {
        return PerformancePeriod.SinceInception;
    }
    const match = Object.entries(config).find(([_, value]) => {
        const selection = currentSelection['date'];
        const current = value['date'];
        return ['dateFrom', 'dateTo', 'filterType', 'type'].every(
            (field) => current[field] === selection[field]
        );
    });
    return match ? (match[0] as PerformancePeriod) : undefined;
}
