import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconStyle, WdxIconModule } from '@wdx/shared/components/wdx-icon';

@Component({
    selector: 'wdx-icon-label',
    standalone: true,
    imports: [CommonModule, WdxIconModule],
    templateUrl: './wdx-icon-label.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxIconLabelComponent {
    @Input() icon?: string;
    @Input() iconStyle: IconStyle = 'fal';
    @Input() label?: string;
    @Input() labelClasses?: string;
    @Input() iconClasses?: string;
}
