<div
    class="info-card d-flex align-items-center"
    [ngClass]="{
        pointer: canSelect,
        'info-card--clickable': linkWholeCard,
    }"
    [attr.data-cy]="primaryInfo"
    (click)="onSelect(); onClick()"
>
    <atom-check-box
        *ngIf="canSelect"
        class="me-2"
        [(checked)]="isSelected"
    ></atom-check-box>

    <div
        *ngIf="(avatars && avatars.length) || icon"
        [class.divider-end]="avatarIconDivider"
        [class.me-2]="!avatarIconDivider"
    >
        <molecule-avatar-group-container
            *ngIf="avatars && avatars.length"
            [avatars]="avatars"
            [size]="size"
        ></molecule-avatar-group-container>

        <wdx-icon-container
            *ngIf="icon"
            size="md"
            [setBackgroundColor]="icon?.hslColorString | textToHsl"
        >
            <wdx-icon [icon]="icon.icon ? icon.icon : icon"></wdx-icon>
        </wdx-icon-container>
    </div>

    <div
        class="info-card__information d-block position-relative text-truncate"
        [class.flex-grow-1]="fillHorizontalSpace"
    >
        <div [class.text-truncate]="!compact">
            <h6
                class="{{ textColor ? 'text-' + textColor : '' }}"
                [class.text-truncate]="!compact"
                [class.title]="!compact"
                data-cy="txt-info-card-search"
            >
                <ng-container
                    *ngIf="!routerLinkData && !modalData && !externalLink"
                >
                    <ng-container *ngIf="!ignoreLinkClickedObservers">
                        <ng-container *ngIf="hasLinkClickedObservers">
                            <a
                                #link
                                href="#"
                                (click)="onLinkClicked($event)"
                                click-prevent-default
                                [attr.data-cy]="
                                    cySelector
                                        ? cySelector
                                        : 'btn-quick-link-' + primaryInfo
                                "
                                [attr.data-cy-value]="cyValue"
                                >{{ primaryInfo | translateTokenisedString }}</a
                            >
                        </ng-container>
                        <ng-container *ngIf="!hasLinkClickedObservers">
                            <span
                                [innerHtml]="
                                    primaryInfo | translateTokenisedString
                                "
                            ></span>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="ignoreLinkClickedObservers">
                        {{ primaryInfo }}
                    </ng-container>
                </ng-container>

                <a
                    *ngIf="externalLink"
                    [href]="externalLink?.externalUrl"
                    [attr.target]="externalLink?.target"
                    [attr.data-cy]="
                        cySelector
                            ? cySelector
                            : 'btn-quick-link-' + primaryInfo
                    "
                    [attr.data-cy-value]="cyValue"
                >
                    {{ primaryInfo }}
                </a>

                <a
                    *ngIf="routerLinkData"
                    #link
                    class="d-block text-truncate info-card-block-link"
                    [routerLink]="!openInNewTab && routerLinkData"
                    [queryParams]="queryParamsData"
                    [attr.data-cy]="
                        cySelector
                            ? cySelector
                            : 'btn-quick-link-' + primaryInfo
                    "
                    [attr.data-cy-value]="cyValue"
                    [innerHtml]="primaryInfo"
                    (click)="onLinkClicked($event)"
                >
                </a>
                <button
                    *ngIf="modalData"
                    class="btn btn-link"
                    [modalId]="modalData?.id"
                    [attr.data-cy]="
                        cySelector
                            ? cySelector
                            : 'btn-quick-link-' + modalData?.id
                    "
                    [attr.data-cy-value]="cyValue"
                >
                    {{ primaryInfo }}
                </button>
                <wdx-icon
                    *ngIf="primaryInfoIcon"
                    class="ms-1"
                    size="fit"
                    [icon]="primaryInfoIcon"
                ></wdx-icon>
            </h6>
            <div
                *ngIf="secondaryInfoTags?.length"
                class="d-flex align-items-center"
            >
                <ng-container
                    *ngFor="
                        let secondaryInfoTag of secondaryInfoTags;
                        let first = first
                    "
                >
                    <ng-container *ngIf="secondaryInfoTag.label">
                        <wdx-badge
                            *ngIf="
                                secondaryInfoTag?.highlight;
                                else noHighlight
                            "
                            class="text-truncate"
                            popoverClass="position-fixed"
                            triggers="mouseenter:mouseleave"
                            [class.ms-1]="!first"
                            [ngbPopover]="secondaryInfoTag.tooltip"
                            [themeColor]="secondaryInfoTag.highlight"
                        >
                            {{
                                secondaryInfoTag.label
                                    | translateTokenisedString
                            }}
                        </wdx-badge>
                        <ng-template #noHighlight>
                            <span
                                class="text-truncate"
                                [class.ms-1]="!first"
                                [title]="secondaryInfoTag.label"
                                >{{
                                    secondaryInfoTag.label
                                        | translateTokenisedString
                                }}</span
                            >
                        </ng-template>
                    </ng-container>
                </ng-container>
            </div>

            <wdx-list
                *ngIf="tertiaryInfo || idList?.length"
                class="small {{
                    textColor ? 'text-' + textColor : 'text-muted'
                }} text-truncate"
            >
                <ng-container *ngIf="tertiaryInfo">
                    <ng-container *ngFor="let item of tertiaryInfo">
                        <ng-container
                            *ngIf="
                                item?.label || item?.label === null;
                                else stringListItem
                            "
                        >
                            <li wdxListItem *ngIf="item?.label">
                                <a [routerLink]="item?.routerLink">{{
                                    item?.label | translateTokenisedString
                                }}</a>
                                <div *ngIf="item.subLabel" wdxListItemSub>
                                    ({{ item.subLabel }})
                                </div>
                                <wdx-icon
                                    *ngIf="item.icon"
                                    wdxListItemIcon
                                    [icon]="item?.icon?.icon"
                                ></wdx-icon>
                            </li>
                        </ng-container>
                        <ng-template #stringListItem>
                            <li
                                class="d-inline-flex text-truncate"
                                [title]="item"
                                wdxListItem
                            >
                                <div class="text-truncate">
                                    {{ item }}
                                </div>
                            </li>
                        </ng-template>
                    </ng-container>
                </ng-container>
                <li *ngIf="idList?.length" class="text-truncate">
                    <molecule-identifier-list
                        class="text-truncate"
                        [idList]="idList"
                    ></molecule-identifier-list>
                </li>
            </wdx-list>
        </div>
    </div>
    <div *ngIf="showEditForm && formSetup" class="ms-2">
        <molecule-action-button
            [actionButton]="formSetup | fn: formSetupAsEditActionButton"
        ></molecule-action-button>
    </div>
    <ng-content select=".custom-actions"></ng-content>
    <atom-icon-button
        *ngIf="canDelete"
        class="ms-2"
        data-cy="info-card-close"
        size="sm"
        btnClass="btn"
        icon="trash-alt"
        (onClick)="onDelete()"
    ></atom-icon-button>
</div>

<table
    *ngIf="subInfoCards?.length"
    class="timeline sub-info-cards sub-info-cards--{{ size }}"
>
    <tr *ngFor="let subInfoCard of subInfoCards" class="sub-info-card">
        <td class="timeline-cell timeline-icon-cell">
            <wdx-icon
                class="timeline-icon"
                size="xs"
                [icon]="['fas', 'circle']"
            ></wdx-icon>
        </td>
        <td class="timeline-cell">
            <molecule-info-card
                [primaryInfo]="subInfoCard.primaryInfo"
                [secondaryInfo]="subInfoCard.secondaryInfo"
                [tertiaryInfo]="subInfoCard.tertiaryInfo"
                [icon]="subInfoCard.icon"
                [showEditForm]="subInfoCard.showEditForm"
                [formSetup]="subInfoCard.formSetup"
                [routerLinkData]="routerLinkData"
                (linkClicked)="onSubInfoCardLinkClicked(subInfoCard)"
            ></molecule-info-card>
        </td>
    </tr>
</table>

<molecule-article-preview
    *ngIf="article?.synopsis"
    class="article-preview--indent d-block py-3"
    [article]="article"
></molecule-article-preview>
