import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { IconWithTextCellRendererParams } from './icon-with-text-cell-renderer.model';

@Component({
    selector: 'wdx-ag-grid-icon-with-text-cell-renderer',
    standalone: true,
    imports: [CommonModule, WdxIconModule],
    template: `
        <div class="d-flex align-items-center w-100 text-truncate">
            @if (icon) {
                <wdx-icon class="me-2" [icon]="icon"></wdx-icon>
            }
            <span class="text-truncate">{{ text }}</span>
        </div>
    `,
})
export class IconWithTextCellRendererComponent
    implements ICellRendererAngularComp
{
    public text?: string;
    public icon?: string;

    public agInit(
        params: ICellRendererParams<any, IconWithTextCellRendererParams>,
    ): void {
        if (params?.value) {
            this.text = params.value.text;
            this.icon = params.value.icon;
        }
    }

    public refresh(
        params: ICellRendererParams<any, IconWithTextCellRendererParams>,
    ): boolean {
        this.agInit(params);
        return true;
    }
}
