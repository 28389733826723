import { Pipe, PipeTransform } from '@angular/core';
import { KeyStatResult, KeyStatValueType } from '@wdx/clmi/api-models';
import {
    DisplayDatePipe,
    ShortCurrencyPipe,
    WdxThemeColor,
} from '@wdx/shared/utils';
import { TileInfo } from '../classes/tile.class';

@Pipe({ name: 'keyStatsToTileInfos' })
export class KeyStatsToTileInfos implements PipeTransform {
    transform(keyStats: KeyStatResult[]): TileInfo[] {
        if (!keyStats?.length) {
            return [];
        }

        return keyStats.map((keyStat) => ({
            ...new TileInfo({
                value:
                    !keyStat.value && keyStat.value !== 0
                        ? String.fromCharCode(8212)
                        : keyStat.valueType === KeyStatValueType.DateTime
                          ? new DisplayDatePipe().transform(keyStat.value)
                          : keyStat.valueType === KeyStatValueType.Currency
                            ? new ShortCurrencyPipe().transform(
                                  keyStat.value.value,
                                  keyStat.value.isoCode,
                              )
                            : keyStat.value,
                label: keyStat.displayName,
                icon: keyStat.icon,
                themeColor: keyStat.themeColor
                    ? (`${keyStat.themeColor}`.toLowerCase() as WdxThemeColor)
                    : undefined,
            }),
        }));
    }
}
