import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmlReport } from '@wdx/clmi/api-models';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class AmlService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    /**
     * Get all AML reports for a given app ID.
     * @param id - The ID of the app.
     * @returns An observable that emits an array of AmlReport objects.
     * @warning Do not call this method directly. Use PepAndSanctionsFacade.loadAllReports() instead
     * to ensure proper state management.
     */
    getAllForId(id: string): Observable<AmlReport[]> {
        return this.http.get<AmlReport[]>(
            `${this.config.getConfiguration().API_BASE}/aml/app/${id}/all`,
        );
    }

    /**
     * Get the latest AML report for a given app ID.
     * @param id - The ID of the app.
     * @returns An observable that emits an AmlReport object.
     * @warning Do not call this method directly. Use PepAndSanctionsFacade.loadLatest() instead
     * to ensure proper state management.
     */
    getLatestForId(id: string): Observable<AmlReport> {
        return this.http.get<AmlReport>(
            `${this.config.getConfiguration().API_BASE}/aml/app/${id}`,
        );
    }

    /**
     * Patch a new AML check for a given app ID.
     * @param id - The ID of the app.
     * @returns An observable that emits an AmlReport object.
     * @warning Do not call this method directly. Use PepAndSanctionsFacade.patchNewCheck() instead
     * to ensure proper state management.
     */
    patchNewCheckForId(id: string): Observable<AmlReport> {
        return this.http.patch<AmlReport>(
            `${this.config.getConfiguration().API_BASE}/aml/app/${id}`,
            {},
        );
    }
}
