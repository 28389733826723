<atom-modal-wrapper
    [modalId]="IMPORT_ENTITIES_MODAL_ID"
    [modalTemplate]="entitiesModal"
>
    <ng-template #entitiesModal>
        <organism-modal
            [modalTitle]="modalTitle"
            (modalClosed)="onModalClosed()"
            [guidanceText]="popoverContent"
        >
            <div
                class="mh-100 overflow-auto"
                wdxListGroup
                [wdxListGroupFlush]="true"
                [wdxIsLoading]="importEntitiesIsLoading$ | ngrxPush"
                [hasError]="importEntitiesHasError$ | ngrxPush"
            >
                @if (!selectedCategory) {
                    @for (
                        category of importEntities$ | ngrxPush;
                        track category.category
                    ) {
                        <button
                            class="d-flex"
                            wdxListGroupItem
                            [wdxListGroupAction]="true"
                            (click)="selectCategory(category)"
                        >
                            <wdx-icon
                                class="me-2"
                                [fixedWidth]="true"
                                [icon]="category.icon"
                            ></wdx-icon>
                            {{
                                category.categoryName?.key
                                    | translateTokenisedString
                            }}

                            <wdx-icon
                                class="ms-auto"
                                [fixedWidth]="true"
                                [icon]="ICON_CHEVRON_RIGHT"
                            ></wdx-icon>
                        </button>
                    }
                }

                @if (selectedCategory) {
                    <button
                        wdxListGroupItem
                        [wdxListGroupAction]="true"
                        (click)="onBackToCategoriesList()"
                    >
                        <wdx-icon
                            class="me-2"
                            [fixedWidth]="true"
                            [icon]="ICON_CHEVRON_LEFT"
                        ></wdx-icon>
                        {{
                            selectedCategory.categoryName?.key
                                | translateTokenisedString
                        }}
                    </button>
                    @for (
                        importType of selectedCategory.importTypes;
                        track importType.code
                    ) {
                        <button
                            wdxListGroupItem
                            [wdxListGroupAction]="true"
                            (click)="onImport(importType.code)"
                            [attr.data-cy]="'import-action-' + importType.code"
                        >
                            <wdx-icon
                                class="me-2"
                                [fixedWidth]="true"
                                icon="file-import"
                            ></wdx-icon>
                            {{
                                importType.displayName.key
                                    | translateTokenisedString
                            }}
                        </button>
                    }
                }
            </div>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>

<ng-template #popoverContent>
    <p class="mb-2">
        {{ IMPORT_ORDER_INFORMATION | translateTokenisedString }}
    </p>

    <wdx-label>{{ IMPORT_ORDER_EXAMPLE | translateTokenisedString }}</wdx-label>

    <ol class="list-group px-3">
        <li>{{ IMPORT_ORDER_REFERENCEDATA | translateTokenisedString }}</li>
        <li>{{ IMPORT_ORDER_PARTY | translateTokenisedString }}</li>
        <li>{{ IMPORT_ORDER_PARTYDETAILS | translateTokenisedString }}</li>
        <li>{{ IMPORT_ORDER_PARTYROLE | translateTokenisedString }}</li>
        <li>{{ IMPORT_ORDER_CLIENT | translateTokenisedString }}</li>
        <li>{{ IMPORT_ORDER_CLIENTROLES | translateTokenisedString }}</li>
        <li>{{ IMPORT_ORDER_PRODUCTS | translateTokenisedString }}</li>
    </ol>

    <a
        class="nav-link pointer ps-0 text-decoration-underline"
        [href]="documentationUrl"
        data-cy="import-docs-link"
        target="_blank"
        >{{ IMPORT_ORDER_SEE_DETAILS | translateTokenisedString }}</a
    >
</ng-template>
