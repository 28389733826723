<wdx-dialog
    [cancelButtonLabel]="CANCEL_BTN | translateTokenisedString"
    [actionButtonLabel]="CONFIRM_BTN | translateTokenisedString"
    [title]="DIALOG_TITLE_REJECT_ITEM | translateTokenisedString"
    [actionButtonStyle]="WDX_BUTTON_STYLE.Danger"
    (wdxOnClick)="onClick()"
>
    <p class="mb-4">
        {{ DIALOG_COMMENT_BODY_REJECT | translateTokenisedString }}
    </p>
    <form [formGroup]="commentForm">
        <wdx-form-field
            [label]="'DASHBOARD_LABEL_COMMENT' | translateTokenisedString"
            class="mb-3 d-block"
        >
            <textarea
                title="comment"
                rows="3"
                formControlName="comment"
                wdxInputText
                [attr.maxlength]="500"
            ></textarea>
        </wdx-form-field>

        <wdx-form-field
            [label]="'DASHBOARD_LABEL_REASON' | translateTokenisedString"
        >
            <wdx-multi-select
                [formControlNameOrIndex]="'reason'"
                [options]="rejectionReasons$ | ngrxPush"
                [multiple]="false"
                [searchable]="false"
            ></wdx-multi-select>
        </wdx-form-field>
    </form>
</wdx-dialog>
