import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component, HostBinding } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';

export interface RefreshColumnHeaderParams {
    refreshCallback: () => void;
}

@Component({
    selector: 'wdx-ag-grid-refresh-header',
    standalone: true,
    imports: [WdxIconModule],
    templateUrl: './refresh-column-header.component.html',
})
export class RefreshColumnHeaderComponent implements IHeaderAngularComp {
    @HostBinding('class') class =
        'd-flex h-100 w-100 align-content-center justify-content-center pointer';

    public params!: IHeaderParams & RefreshColumnHeaderParams;

    agInit(params: IHeaderParams & RefreshColumnHeaderParams): void {
        this.params = params;
    }

    refresh(params: IHeaderParams) {
        return false;
    }

    refreshClick() {
        this.params.refreshCallback();
    }
}
