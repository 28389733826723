import { IRowNode } from '@ag-grid-community/core';

export function stringComparator(a: string, b: string) {
    if (a == b) {
        return 0;
    }
    return a > b ? 1 : -1;
}

export function numberFieldComparator(field: string) {
    return (_: any, __: any, a: IRowNode, b: IRowNode) =>
        a?.data?.[field] - b?.data?.[field];
}

export function numberFieldPropertyComparator(field: string, property: string) {
    return (_: any, __: any, a: IRowNode, b: IRowNode) =>
        a?.data?.[field][property] - b?.data?.[field][property];
}
