import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import {
    Avatar,
    AvatarDetailPopup,
    WdxAvatarModule,
} from '@wdx/shared/components/wdx-avatar';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';
import { WdxStopPropagationDirective } from '@wdx/shared/components/wdx-stop-propagation';
import { Observable } from 'rxjs';
import { stringComparator } from '../../utils';

export interface AvatarCellRendererValue {
    avatars: Avatar[];
    label?: string;
    url?: string;
    newTab?: boolean;
}

export interface AvatarCellRendererParams {
    popupContent$(avatar: Avatar): Observable<AvatarDetailPopup>;
}

export const avatarCellRendererComparator = (
    valueA: AvatarCellRendererValue,
    valueB: AvatarCellRendererValue,
) =>
    stringComparator(
        valueA?.avatars?.[0]?.text || '',

        valueB?.avatars?.[0]?.text || '',
    );

export const AVATAR_CELL_RENDERER_COL_DEFS: ColDef = {
    width: 80,
    maxWidth: 95,
};

@Component({
    selector: 'wdx-ag-grid-avatar-cell-renderer',
    standalone: true,
    imports: [
        CommonModule,
        WdxAvatarModule,
        PushPipe,
        WdxSpinnerModule,
        WdxStopPropagationDirective,
        RouterModule,
    ],
    templateUrl: './avatar-cell-renderer.component.html',
    styleUrls: ['./avatar-cell-renderer.component.scss'],
})
export class AvatarCellRendererComponent implements ICellRendererAngularComp {
    @HostBinding('class') class = 'd-flex align-items-center h-100';

    public avatars?: Avatar[];
    public label?: string;
    public url?: string;
    public newTab?: boolean;

    public popupEnabled = false;
    public popupContent$?: Observable<AvatarDetailPopup>;
    private _popupContent$?: (avatar: Avatar) => Observable<AvatarDetailPopup>;

    public agInit(
        params: ICellRendererParams<any, AvatarCellRendererValue>,
    ): void {
        this.avatars = params.value?.avatars || [];
        this.label = params.value?.label;
        this.url = params.value?.url;
        this.newTab = params.value?.newTab;

        if (params.colDef?.cellRendererParams?.popupContent$) {
            this.popupEnabled = true;
            this._popupContent$ =
                params.colDef?.cellRendererParams?.popupContent$;
        }
    }

    public refresh(
        params: ICellRendererParams<any, AvatarCellRendererValue>,
    ): boolean {
        this.agInit(params);
        return true;
    }

    public avatarClicked(avatar: Avatar) {
        if (this._popupContent$) {
            this.popupContent$ = this._popupContent$(avatar);
        }
    }
}
