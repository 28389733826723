import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { ICellRendererParams, ColDef } from '@ag-grid-community/core';
import { WdxIconLabelComponent } from '@wdx/shared/components/wdx-icon-label';

export const ISSUE_COUNT_CELL_RENDERER_COL_DEFS: ColDef = {
    width: 70,
    maxWidth: 80,
};

@Component({
    selector: 'wdx-ag-grid-issues-cell-renderer',
    standalone: true,
    imports: [CommonModule, WdxIconLabelComponent],
    templateUrl: './issues-count-cell-renderer.component.html',
})
export class IssuesCountRendererComponent implements ICellRendererAngularComp {
    @HostBinding('class') class = 'p-2';

    public count?: number | null;

    agInit(params: ICellRendererParams<any, number>): void {
        this.count = params?.value;
    }

    refresh(params: ICellRendererParams<any, number>): boolean {
        this.count = params?.value;
        return true;
    }
}
