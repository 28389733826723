import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';

import {
    AccordionPanelChangeEvent,
    FormFieldType,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
    ILayoutAndSchemaFieldDefinition,
    ReactiveFormElement,
    ValidationSummaryError,
    ValidationSummarySection,
} from '../../../models';
import {
    FormBuilderService,
    FormStaticService,
    FormValidationService,
} from '../../../services';

@Component({
    selector: 'wdx-ff-form-array',
    templateUrl: './form-framework-form-array.component.html',
})
export class FormFrameworkFormArrayComponent
    extends WdxDestroyClass
    implements OnInit, AfterViewInit
{
    @Input() entityId!: string;
    @Input() elementDefinition!: ILayoutAndSchemaFieldDefinition;
    @Input() fbService!: FormBuilderService;
    @Input() formContext!: any;
    @Input() parentData!: any;
    @Input() readonlyMode?: boolean;

    disabled = false;
    openPanels = [];
    openChildPanels = [];

    validationStatus!: boolean;
    validationSummary: ValidationSummarySection[] = [];
    @ViewChildren('subForm', { read: ElementRef })
    subForms!: QueryList<ElementRef>;
    @ViewChildren('grandChildForm', { read: ElementRef })
    grandChildForms!: QueryList<ElementRef>;

    @ViewChild(NgbAccordionDirective) accordion!: NgbAccordionDirective;
    @ViewChildren('panelToggle') panelToggles!: QueryList<ElementRef>;

    readonly FORM_FIELD_TYPE = FormFieldType;
    readonly VALIDATION_SUMMARY_MESSAGE =
        'Section contains missing values or validation errors';

    constructor(
        public controlContainer: ControlContainer,
        private formValidationService: FormValidationService,
        private formStaticService: FormStaticService,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit(): void {
        this.formStaticService.submitAttempted$.subscribe(() => {
            this.calculateValidationErrors();
            this.controlContainer.control?.statusChanges
                .pipe(takeUntil(this.destroyed$), debounceTime(200))
                .subscribe(() => {
                    this.calculateValidationErrors();
                });
        });
    }

    ngAfterViewInit(): void {
        this.disableInactiveControls();

        this.subForms.changes
            .pipe(takeUntil(this.formStaticService.destroyed$))
            .subscribe(() => this.disableInactiveControls());

        this.cd.detectChanges();
    }

    calculateValidationErrors(): void {
        this.validationStatus = !!this.controlContainer.control?.invalid;
        this.validationSummary = this.formValidationService.getArrayErrors(
            this.elementDefinition.children as ReactiveFormElement[],
            (this.controlContainer.control as any)
                .controls as UntypedFormGroup[],
        );
        this.cd.detectChanges();
    }

    disableAllControls() {
        this.disableControls(true);
    }

    disableInactiveControls() {
        const availableKeys = (
            this.elementDefinition?.children?.[0]
                ?.layoutAndDefinition?.[0] as unknown as ILayoutAndSchemaFieldDefinition[]
        )?.map((def) => def.name) as string[];

        this.disableControls(false, availableKeys);
    }

    disableControls(allControls: boolean, availableKeys?: string[]) {
        (this.controlContainer.control as any).controls.forEach(
            (formGroup: UntypedFormGroup) => {
                Object.entries(formGroup.controls).forEach(
                    ([name, control]) => {
                        if (!availableKeys?.includes(name) || allControls) {
                            control.disable({ emitEvent: false });
                        }
                    },
                );
            },
        );
    }

    trackSubForm(_: any, section: any) {
        return section.index;
    }

    trackSubFormElement(_: any, section: any) {
        return section.name;
    }

    panelChange(panelId: string, collapsed: boolean): void {
        this.addRemoveFromNgPanelArray({ panelId, collapsed }, 'openPanels');
    }

    panelChildChange(panelId: string, collapsed: boolean): void {
        this.addRemoveFromNgPanelArray(
            { panelId, collapsed },
            'openChildPanels',
        );
    }

    addRemoveFromNgPanelArray(
        props: AccordionPanelChangeEvent,
        panelArrayVar: string,
    ): void {
        const STR_ARR = props.panelId.split('-');
        const TYPE = STR_ARR[STR_ARR.length - 2];
        const INDEX = `${TYPE}-${STR_ARR[STR_ARR.length - 1]}`;

        if (!props.collapsed) {
            (this as any)[panelArrayVar].push(INDEX);
            (this as any)[panelArrayVar] = [...(this as any)[panelArrayVar]];
        }

        if (props.collapsed) {
            (this as any)[panelArrayVar] = [
                ...(this as any)[panelArrayVar].filter(
                    (panelIndex: string) => panelIndex !== INDEX,
                ),
            ];
        }
    }

    addToArray(elementDefinition: ILayoutAndSchemaFieldDefinition) {
        this.markFormAsDirty();
        this.fbService?.addToArray(elementDefinition);
        const addedIndex =
            this.fbService?.firstLevel[elementDefinition.name as string]
                ?.formArray?.controls?.length - 1;

        const addPanelId = `ngb-panel-${elementDefinition.name}-child-${addedIndex}`;
        this.cd.detectChanges();
        this.accordion.expand(addPanelId);
        this.panelToggles.last.nativeElement.focus();
    }

    removeFromArray(
        elementDefinition: ILayoutAndSchemaFieldDefinition,
        index: number,
    ) {
        this.markFormAsDirty();
        this.fbService.removeFromArray(elementDefinition, index);
    }

    onErrorClicked(
        error: ValidationSummaryError,
        index: number,
        grandChildIndex?: number,
    ): void {
        const selector = `[data-form-control='${error.name}']`;
        const scrollToEl = grandChildIndex
            ? this.grandChildForms
                  .get(grandChildIndex)
                  ?.nativeElement.querySelector(selector)
            : this.subForms.get(index)?.nativeElement.querySelector(selector);
        this.formValidationService.scrollToError(scrollToEl);
    }

    private markFormAsDirty() {
        // settimeout required to ensure dirty state isn't reset before form changed event is emitted
        setTimeout(() => {
            this.formStaticService.form.markAsDirty();
        }, 0);
    }
}
