import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { WdxSwitchModule } from '@wdx/shared/components/wdx-switch';

export interface SwitchRendererValue {
    toggled: boolean;
    disabled?: boolean;
    label?: string;
    toggleCallBack?: (on: boolean) => void;
}

@Component({
    selector: 'wdx-ag-grid-switch-cell-renderer',
    standalone: true,
    imports: [CommonModule, WdxSwitchModule],
    template:
        '<wdx-switch [toggled]="toggled" [label]="label" [disabled]="disabled" (toggle)="onToggle($event)"></wdx-switch>',
})
export class SwitchRendererComponent implements ICellRendererAngularComp {
    @HostBinding('class') class = 'align-items-center d-flex h-100';

    public url?: string;
    public toggled?: boolean;
    public disabled?: boolean;
    public label?: string;
    public toggleCallBack?: (on: boolean) => void;

    public agInit(params: ICellRendererParams<any, SwitchRendererValue>): void {
        if (params?.value) {
            this.toggled = params.value.toggled;
            this.disabled = params.value.disabled;
            this.label = params.value.label;
            this.toggleCallBack =
                params.value.toggleCallBack || (() => undefined);
        }
    }

    public refresh(
        params: ICellRendererParams<any, SwitchRendererValue>,
    ): boolean {
        this.agInit(params);
        return true;
    }

    public onToggle(on: boolean): void {
        this.toggleCallBack && this.toggleCallBack(on);
    }
}
