import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { TranslatePipe, TranslateTokenisedStringPipe } from '@wdx/shared/utils';
import { AtomFeatureSvgModule } from '../../../components/atoms/atom-feature-svg/atom-feature-svg.module';
import { AtomModalWrapperModule } from '../../../components/atoms/atom-modal-wrapper/atom-modal-wrapper.module';
import { MoleculeAccordionModule } from '../../../components/molecules/molecule-accordion/molecule-accordion.module';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';
import { OrganismCasesListModule } from '../../../components/organisms/organism-cases-list/organism-cases-list.module';
import { OrganismModalModule } from '../../../components/organisms/organism-modal/organism-modal.module';
import { OrganismOpportunitiesListModule } from '../../../components/organisms/organism-opportunities-list/organism-opportunities-list.module';
import { WidgetActivitiesListModule } from '../../../components/widgets/widget-activities-list/widget-activities-list.module';
import { WidgetCasesListModule } from '../../../components/widgets/widget-cases-list/widget-cases-list.module';
import { WidgetOpportunitiesListModule } from '../../../components/widgets/widget-opportunities-list/widget-opportunities-list.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { MoleculeArticleCardModule } from '../../../features/articles/shared/components/molecule-article-card/molecule-article-card.module';
import { OrganismArticlesListModule } from '../../../features/articles/shared/components/organism-articles-list/organism-articles-list.module';
import { WidgetArticlesListModule } from '../../../features/articles/shared/components/widget-articles-list/widget-articles-list.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { RelatedActivitiesComponent } from './related-activities/related-activities.component';
import { RelatedArticlesComponent } from './related-articles/related-articles.component';
import { RelatedCasesComponent } from './related-cases/related-cases.component';
import { RelatedOpportunitiesComponent } from './related-opportunities/related-opportunities.component';
import { RelatedRecordsComponent } from './related-records.component';

@NgModule({
    declarations: [
        RelatedRecordsComponent,
        RelatedCasesComponent,
        RelatedOpportunitiesComponent,
        RelatedArticlesComponent,
        RelatedActivitiesComponent,
    ],
    imports: [
        AtomFeatureSvgModule,
        AtomModalWrapperModule,
        CommonModule,
        DirectivesModule,
        MoleculeAccordionModule,
        MoleculeActionButtonModule,
        MoleculeArticleCardModule,
        OrganismArticlesListModule,
        OrganismCasesListModule,
        OrganismModalModule,
        OrganismOpportunitiesListModule,
        PushPipe,
        WidgetActivitiesListModule,
        WidgetArticlesListModule,
        WidgetCasesListModule,
        WidgetOpportunitiesListModule,
        PipesModule,
        TranslatePipe,
        TranslateTokenisedStringPipe,
    ],
    exports: [RelatedRecordsComponent],
})
export class RelatedRecordsModule {}
