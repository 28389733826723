import { DirectivesModule } from '../../../directives/directives.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AtomAddButtonModule } from '../../atoms/atom-add-button/atom-add-button.module';
import { AtomIconButtonModule } from '../../atoms/atom-icon-button/atom-icon-button.module';
import { MoleculeAddInfoCardModule } from '../../molecules/molecule-add-info-card/molecule-add-info-card.module';
import { MoleculeInfoCardModule } from '../../molecules/molecule-info-card/molecule-info-card.module';
import { OrganismAddInfoCardsComponent } from './organism-add-info-cards.component';
import { FunctionPipe } from '@wdx/shared/utils';
import { MoleculeActionButtonModule } from '../../../components/molecules/molecule-action-button/molecule-action-button.module';

@NgModule({
    imports: [
        AtomIconButtonModule,
        AtomAddButtonModule,
        CommonModule,
        DirectivesModule,
        MoleculeAddInfoCardModule,
        MoleculeInfoCardModule,
        FunctionPipe,
        MoleculeActionButtonModule,
    ],
    declarations: [OrganismAddInfoCardsComponent],
    exports: [OrganismAddInfoCardsComponent],
})
export class OrganismAddInfoCardsModule {}
