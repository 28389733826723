import {
    AppGroupType,
    AppStatus,
    HeatMapEntry,
    Regarding,
} from '@wdx/clmi/api-models';
import { LookupSourceType, SystemEntity } from '@wdx/shared/utils';

export enum TriggerChar {
    'At' = '@',
    'Hash' = '#',
}

export interface IAppMention {
    displayName?: string;
    app: AppStatus | HeatMapEntry;
    caseId: string;
    workItemId: string;
    regarding: (Omit<Regarding, 'type'> & {
        type: SystemEntity | LookupSourceType;
    })[];
    triggerChar: TriggerChar;
    type: AppGroupType;
}
