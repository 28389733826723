import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TranslationsService } from '@wdx/shared/utils';

export interface RouterLinkRendererValue {
    url: string;
    label: string;
    newTab?: boolean;
}

@Component({
    selector: 'wdx-ag-grid-link-cell-renderer',
    standalone: true,
    imports: [CommonModule, RouterModule],
    template:
        '<a *ngIf="url" [routerLink]="[url]" [target]="target">{{label}}</a>',
})
export class LinkRendererComponent implements ICellRendererAngularComp {
    private translationsService = inject(TranslationsService);

    public label?: string;
    public url?: string;
    public target: '_self' | '_blank' = '_self';

    agInit(params: ICellRendererParams<any, RouterLinkRendererValue>): void {
        if (params?.value) {
            this.updateConfig(params.value);
        }
    }

    refresh(
        params: ICellRendererParams<any, RouterLinkRendererValue>
    ): boolean {
        if (params?.value) {
            this.updateConfig(params.value);
        }
        return true;
    }

    private updateConfig(value: RouterLinkRendererValue) {
        this.label = this.translationsService.translateTokenisedString(
            value.label
        );
        this.url = value.url;
        if (value?.newTab) {
            this.target = '_blank';
        }
    }
}
