import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { SystemApiService } from '@wdx/clmi/api-services/services';
import {
    Config,
    LocalStorageKey,
    LocalStorageService,
    ROUTE_PREFIX,
} from '@wdx/shared/utils';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { SESSION_IDLE_TIME_MINS } from '../constants/authentication.constants';
import { SystemSettingsService } from '../shared/services';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
    config: Config = null;
    isAuthenticated$: Observable<boolean> = null;
    auth0Service: AuthService;

    // Create subject and public observable of user profile data
    private userProfileSubject$ = new BehaviorSubject<any>(null);
    userProfile$ = this.userProfileSubject$.asObservable();

    // Create subject and public observable of user profile data
    private authenticatingSubject$ = new BehaviorSubject<boolean>(null);
    isAuthenticating$ = this.authenticatingSubject$.asObservable();

    // Create a local property for login status
    loggedIn: boolean = null;

    notifyTimeoutModal$ = new Subject<number>();
    destroyed$ = new Subject<boolean>();
    sessionExpiryTime: Date;
    interval: NodeJS.Timeout;

    constructor(
        private environment: ConfigService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private systemSettingsService: SystemSettingsService,
        private systemApiService: SystemApiService,
    ) {
        this.config = this.environment.getConfiguration();
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    initSessionCountDown() {
        this.setSessionExpiryTime();

        this.interval = setInterval(() => {
            const dateDiff: number = Math.floor(
                (this.sessionExpiryTime.getTime() - new Date().getTime()) /
                    1000,
            );

            if (dateDiff < 0) {
                this.logout();
            } else {
                this.notifyTimeoutModal$.next(dateDiff);
            }
        }, 1000);
    }

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    getUser$(): Observable<any> {
        return this.auth0Service.user$.pipe(
            tap((user) => this.userProfileSubject$.next(user)),
        );
    }

    /**
     * Triggers loginWithRedirectLoginCall
     * @param returnTo An optional returnTo url. Note this is a tokenised.
     */
    login(options?: { returnToUrl?: string }) {
        // Take user to Auth0 to authenticate
        this.auth0Service.loginWithRedirect({
            appState: {
                target: ROUTE_PREFIX[0],
                ...(options?.returnToUrl && {
                    returnToUrl: options.returnToUrl,
                }),
            },
            authorizationParams: {
                redirect_uri: `${window.location.origin}`,
            },
        });
    }

    getAppState$() {
        return this.auth0Service.appState$;
    }

    setSessionExpiryTime(): void {
        // set the time the session will expire
        const IDLE_TIME_MINS =
            typeof this.systemSettingsService?.get()
                ?.WebApplicationTimeoutSeconds === 'string'
                ? this.systemSettingsService?.get()
                      .WebApplicationTimeoutSeconds / 60
                : SESSION_IDLE_TIME_MINS;

        const expiresAt = new Date(
            IDLE_TIME_MINS * 60 * 1000 + new Date().getTime(),
        );

        this.localStorageService.setStringKey(
            `${LocalStorageKey.ExpiresAt}`,
            expiresAt.toString(),
        );

        this.sessionExpiryTime = expiresAt;
    }

    isAuthenticated(): boolean {
        const expiresAt: Date = new Date(
            this.localStorageService.getStringKey(
                `${LocalStorageKey.ExpiresAt}`,
            ),
        );

        return new Date() < expiresAt;
    }

    logout() {
        this.localStorageService.setStringKey(`${LocalStorageKey.ExpiresAt}`);
        // Ensure Auth0 client instance exists

        // Call method to log out
        this.auth0Service.logout({
            // eslint-disable-next-line camelcase
            clientId:
                this.environment.getConfiguration().AuthenticationClientId,
            logoutParams: {
                returnTo: `${window.location.origin}`,
            },
        });
    }

    getTokenSilently$(
        options?,
    ): Observable<string | GetTokenSilentlyVerboseResponse> {
        return this.auth0Service.getAccessTokenSilently(options).pipe(
            catchError((error) => {
                // Error recieved from Auth0 could be one of:
                // - login_required
                // - consent_required
                // - interaction_required

                // eslint-disable-next-line no-console
                console.error(
                    `Error getting token from Auth0: ${error.error} - ${error.error_description}`,
                );

                // In any case, we want to redirect user to go through authentication
                this.authenticate(this.router.url);
                return EMPTY;
            }),
        );
    }

    authenticate(redirectUrl: string) {
        // The authentication process at the moment involves starting at the welcome screen
        // and clicking the login button. In theory, we could skip this and go straight to Auth0
        // but we would need to then think about the behaviour of the log out button

        if (this.config.TestMode) {
            return;
        }

        // First check we are not already on the welcome screen
        if (!this.router.routerState.snapshot.url.startsWith('/')) {
            this.router.navigate(['/', { redirect: redirectUrl }]);
        }
    }

    isLoggedIn() {
        return this.loggedIn;
    }

    /**
     * Makes an api request to tokeniseurl system api as part of the login process
     * Converts the returned Blob into a string and performs a location.replace
     * Redirects to app home if no url match
     * @param returnTo - The url to be tokenised and redirected to
     */
    returnTo(returnToUrl: string) {
        this.systemApiService
            .getTokenisedUrl(`"${returnToUrl}"`)
            .pipe(
                take(1),
                catchError(() => {
                    this.router.navigate(ROUTE_PREFIX);
                    return EMPTY;
                }),
            )
            .subscribe(async (tokeniseUrlasBlob: Blob) => {
                try {
                    const url = await new Response(tokeniseUrlasBlob).text();
                    this.redirectToUrl(url);
                } catch (e) {
                    this.router.navigate(ROUTE_PREFIX);
                }
            });
    }

    /**
     * Provides a way to redirect to a url without first requiring a call to the
     * tokeniseurl api in the case that it's either not needed, or not working
     * @param url - The url to be redirected to
     */
    redirectToUrl(url: string): void {
        window.location.replace(url);
    }
}
