import {
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IServerSideDatasource,
    ModuleRegistry,
    IServerSideGetRowsParams,
    SortChangedEvent,
    RowClickedEvent,
} from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    inject,
    EventEmitter,
    Output,
} from '@angular/core';

import { FilterHttpService } from '@wdx/shared/infrastructure/api-service';
import {
    FilterFieldType,
    FilterOperator,
    Query,
    QueryExpression,
    SortDirection,
    SystemEntity,
    WdxAgGridThemeDirective,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { take } from 'rxjs';
import { SERVER_SIDE_DEFAULT_GRID_OPTIONS } from '../../constants/entity-ag-grid-config';
import { CommonModule } from '@angular/common';
import { AgGridAngular } from '@ag-grid-community/angular';
import { WdxIsLoadingModule } from '@wdx/shared/components/wdx-is-loading';

ModuleRegistry.registerModules([ServerSideRowModelModule]);

export type WdxColDef = ColDef & {
    wdx?: {
        sortTarget?: string;
    };
};

@Component({
    selector: 'wdx-ag-grid-entity-table',
    templateUrl: './entity-ag-grid-table.component.html',
    styleUrls: ['./entity-ag-grid-table.component.scss'],
    imports: [
        CommonModule,
        AgGridAngular,
        WdxAgGridThemeDirective,
        WdxIsLoadingModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class EntityAgGridTableComponent extends WdxDestroyClass {
    private filterHttpService = inject(FilterHttpService);

    @Input() loading = false;
    @Input() gridOptions: GridOptions = SERVER_SIDE_DEFAULT_GRID_OPTIONS;
    @Input() autoGroupColumnDef: ColDef = {};
    private _columnDefs: WdxColDef[] = [];
    @Input() set columnDefs(value: WdxColDef[]) {
        this._columnDefs = value;
        if (this.gridApi) {
            this.gridApi.setGridOption('columnDefs', []);
            // settimeout required to ensure the column headers update properly
            // we need to set the columnDefs to empty array, then allow time for that apply before setting our desired config
            setTimeout(() => {
                this.gridApi?.setGridOption('columnDefs', value);
                this.gridApi?.refreshServerSide({ purge: true });
            }, 0);
        }
    }
    get columnDefs(): WdxColDef[] {
        return this._columnDefs;
    }
    private _entityType!: SystemEntity;
    @Input() set entityType(value: SystemEntity) {
        this._entityType = value;
        if (this.gridApi) {
            this.gridApi?.refreshServerSide({ purge: true });
        }
    }
    private _queryExpressions: QueryExpression[] = [];
    @Input() set queryExpressions(value: QueryExpression | QueryExpression[]) {
        if (!Array.isArray(value)) {
            this._queryExpressions = [value];
        } else {
            this._queryExpressions = value;
        }
        if (this.gridApi) {
            this.gridApi?.refreshServerSide({ purge: true });
        }
    }

    @Input() pageSize = 50;
    private _searchText?: string;
    @Input() set searchText(value: string | undefined) {
        this._searchText = value;
        this.gridApi?.refreshServerSide({ purge: true });
    }

    @Output() rowCount = new EventEmitter<number>();
    @Output() sortChange = new EventEmitter<Query>();

    @HostBinding('class') class = 'h-100 d-flex flex-column';
    private gridApi?: GridApi;

    public defaultColDef: ColDef = {
        cellClass: [],
    };

    private _onRowClicked: (event: RowClickedEvent) => void = (
        event: RowClickedEvent,
    ) => undefined;

    @Input() set onRowClicked(value: (event: RowClickedEvent) => void) {
        if (
            !value ||
            (this.defaultColDef.cellClass as string[]).includes('pointer')
        ) {
            return;
        }
        this._onRowClicked = value;
        (this.defaultColDef.cellClass as string[]).push('pointer');
    }

    public get onRowClicked(): (event: RowClickedEvent) => void {
        return this._onRowClicked;
    }

    public onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.updateDataSource();
    }

    public onSortChanged(params: SortChangedEvent) {
        const column = params.columns?.find((col) => Boolean(col.getSort()));
        if (column) {
            this.sortChange.emit({
                sortBy: column.getColId(),
                sortDirection:
                    column.getSort() === 'asc'
                        ? SortDirection.Ascending
                        : column.getSort() === 'desc'
                          ? SortDirection.Descending
                          : undefined,
            });
        } else {
            this.sortChange.emit({
                sortDirection: undefined,
                sortBy: undefined,
            });
        }
    }

    private updateDataSource() {
        const dataSource = this.entityDataSource();

        this.gridApi?.setGridOption('serverSideDatasource', dataSource);
    }

    private loadData(
        pageNumber: number,
        entityType: SystemEntity,
        queryExpressions: QueryExpression[] = [],
        pageSize = 50,
        sortBy?: string,
        sortDirection?: SortDirection,
    ) {
        const sorting =
            sortBy && sortDirection
                ? {
                      sortBy,
                      sortDirection,
                  }
                : {};

        return this.filterHttpService.getRecords(
            entityType,
            { pageSize, pageNumber },
            {
                expressions: queryExpressions,
                ...sorting,
            },
        );
    }

    private entityDataSource(): IServerSideDatasource {
        return {
            getRows: (params) => {
                this.getRows(params);
            },
        };
    }

    private getRows(params: IServerSideGetRowsParams<any, any>) {
        const page = Math.ceil(
            (params.request.endRow || 0) /
                (this.gridOptions.cacheBlockSize || 50),
        );
        const sortBy = this.columnDefs.find(
            (col) => col.colId === params.request.sortModel?.[0]?.colId,
        )?.wdx?.sortTarget;
        const sort = params.request.sortModel?.[0]?.sort;
        const sortDirection =
            sort === 'asc'
                ? SortDirection.Ascending
                : sort === 'desc'
                  ? SortDirection.Descending
                  : undefined;
        const queries = [
            ...this._queryExpressions,
            ...(this._searchText
                ? [
                      {
                          field: FilterFieldType.Search,
                          operator: FilterOperator.Matches,
                          values: [this._searchText],
                      },
                  ]
                : []),
        ];

        this.loadData(
            page,
            this._entityType,
            queries,
            this.pageSize,
            sortBy,
            sortDirection,
        )
            .pipe(take(1))
            .subscribe({
                next: (data) => {
                    this.rowCount.emit(data.paging.totalRecords);
                    params.success({
                        rowData: data.results,
                        rowCount: data.paging.totalRecords,
                    });
                },
                error: () => params.fail(),
            });
    }
}
