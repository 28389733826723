import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    inject,
} from '@angular/core';

import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import {
    Action,
    FieldDefinition,
    FieldDefinitionOption,
    FormConditions,
    FormDataResult,
    FormDefinition,
    FormElementLayoutDefinition,
    FormFunctionResult,
    FormSectionLayoutDefinition,
    Trigger,
} from '@wdx/clmi/api-models';
import { FormElementType, WdxDestroyClass } from '@wdx/shared/utils';

import {
    FormLockService,
    FormPendingChangesService,
    FormSummaryLayoutService,
    FormSummaryService,
    FormTriggersService,
    IFormDynamicData,
    dynamicFormsActions,
} from '@wdx/shared/infrastructure/form-framework';
import { FORM_SUMMARY_CHANGE_OF_CIRCUMSTANCE } from '../../../../../constants/form-summary.constants';
import { IClmiClick } from '../../../../../interfaces/clmi-click.interface';
import { FormSummaryPendingChangesService } from '../../services/form-pending-changes/form-pending-changes.service';
import * as R from 'ramda';

@Component({
    selector: 'organism-form-summary',
    templateUrl: './organism-form-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FormPendingChangesService],
})
export class OrganismFormSummaryComponent
    extends WdxDestroyClass
    implements OnInit, OnChanges
{
    @Input() formId: string;
    @Input() appId: string;
    @Input() caseId: string;
    @Input() customEndpoint: string;
    @Input() extraContext?: Record<string, any>;
    @Input() externalForm = false;

    // When true, an external action is in process that will effect the form data
    @Input() isPreLoading: boolean;

    @Input() set entityId(entityId: string) {
        if (!entityId) {
            return;
        }
        this._entityId = entityId;
        this.render(entityId);
    }

    get entityId() {
        return this._entityId;
    }

    @Output() checkIfFormLocked = new EventEmitter<boolean>();
    @Output() clmiOnClick = new EventEmitter<IClmiClick>();

    get summaryInfoCards$(): Observable<FieldDefinitionOption[]> {
        return this.formSummaryLayoutService.summaryInfoCards$;
    }

    data$ = new BehaviorSubject<Record<string, any>>(null);
    formDetails$ = new BehaviorSubject<FormDataResult>(null);
    layoutDefinition$ = new BehaviorSubject<FormElementLayoutDefinition>(null);
    definition$ = new BehaviorSubject<FormDefinition>(null);
    isLoading$: Observable<boolean>;
    subFormData: any;
    subFormData$ = new BehaviorSubject(null);
    FORM_SUMMARY_CHANGE_OF_CIRCUMSTANCE = FORM_SUMMARY_CHANGE_OF_CIRCUMSTANCE;

    _entityId: string;

    public formSummaryPendingChangesService = inject(
        FormSummaryPendingChangesService,
    );
    private actionsSubject$ = inject(ActionsSubject);
    private dynamicDataService = inject(IFormDynamicData);
    private formLockService = inject(FormLockService);
    private formTriggersService = inject(FormTriggersService);
    private formSummaryService = inject(FormSummaryService);
    private formSummaryLayoutService = inject(FormSummaryLayoutService);

    ngOnInit(): void {
        if (this.customEndpoint) {
            this.render(null);
        }

        if (!this.appId) {
            this.actionsSubject$
                .pipe(
                    takeUntil(this.destroyed$),
                    ofType(
                        ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions,
                    ),
                    filter((action: any) => action.formId === this.formId),
                )
                .subscribe((action: any) => {
                    this.render(action.response.entityId);
                });
        }
    }

    /**
     * When 'isPreLoading' has tranisitioned from true to false, re-render
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.isPreLoading?.previousValue &&
            !changes.isPreLoading?.currentValue
        ) {
            this.render(this.entityId);
        }
    }

    render(entityId: string): void {
        this.data$.next(null);

        this.dynamicDataService
            .getFormLayoutAndDefinition(this.formId)
            .pipe(
                take(1),
                switchMap(({ definition, layoutAndDefinitions }) =>
                    this.formSummaryService
                        .getFormData(
                            this.formId,
                            entityId,
                            definition,
                            this.customEndpoint,
                            this.appId,
                            this.externalForm,
                        )
                        .pipe(
                            takeUntil(this.destroyed$),
                            filter((formData) => Boolean(formData)),
                            map((formData) =>
                                this.extraContext
                                    ? {
                                          ...formData,
                                          context: R.mergeDeepRight(
                                              formData || {},
                                              this.extraContext,
                                          ),
                                      }
                                    : formData,
                            ),
                            switchMap((formData) =>
                                this.formSummaryService.getInitialisationFunctions(
                                    this.formId,
                                    definition,
                                    formData,
                                ),
                            ),
                            map(({ formData, formFunctionResults }) => {
                                formData =
                                    this.formSummaryService.applyInitialisationUpdateFields(
                                        definition.conditions,
                                        formData,
                                    );
                                return {
                                    layoutAndDefinition: {
                                        sectionLayoutDefinitions:
                                            this.getLayoutAndDefinitionsWithConditions(
                                                definition,
                                                layoutAndDefinitions,
                                                formFunctionResults,
                                                formData,
                                            ),
                                    },
                                    data: formData.data,
                                    formDetails: formData,
                                    definition: definition,
                                };
                            }),
                        ),
                ),
            )
            .subscribe(
                ({ layoutAndDefinition, data, formDetails, definition }) => {
                    this.formSummaryPendingChangesService.updatePendingData(
                        layoutAndDefinition,
                        formDetails,
                    );

                    this.layoutDefinition$.next({
                        ...layoutAndDefinition,
                        name: '',
                        elementType: FormElementType.Field,
                    });
                    this.definition$.next(definition);
                    this.data$.next(data);
                    this.formDetails$.next(formDetails);
                    this.formSummaryLayoutService.getSummaryInfoCards(
                        definition,
                        data,
                    );
                    this.checkIfFormLocked.emit(
                        this.formLockService.hasFullFormLock(formDetails.lock),
                    );
                },
            );
    }

    getLayoutAndDefinitionsWithConditions(
        definition: FormDefinition,
        layoutAndDefinitions: FormSectionLayoutDefinition[],
        formFunctionResults: FormFunctionResult[],
        formData: FormDataResult,
    ) {
        const { layoutAndDefinitionsWithConditions, subFormData } =
            this.formSummaryService.getLayoutAndDefinitionsWithConditions(
                definition,
                layoutAndDefinitions,
                formFunctionResults,
                formData,
                this.subFormData,
            );

        if (subFormData) {
            this.subFormData$.next(subFormData);
        }

        return layoutAndDefinitionsWithConditions;
    }

    getActions(
        current: Trigger,
        fieldValue: any,
        action: keyof Action,
        layoutAndDefinitions?: FormSectionLayoutDefinition[],
    ) {
        const NAMES = current.field.split('.');
        let data = [];
        const children = [];

        if (
            NAMES?.length > 1 &&
            Array.isArray(fieldValue) &&
            fieldValue?.length
        ) {
            const FIRST_NAME = NAMES[0];
            const SECOND_NAME = NAMES[1];
            let elementLayoutInfo;

            layoutAndDefinitions?.forEach((layoutAndDefinition) => {
                layoutAndDefinition.elementLayoutDefinitions?.forEach(
                    (elementLayoutDefinition) => {
                        if (FIRST_NAME === elementLayoutDefinition.name) {
                            elementLayoutInfo = elementLayoutDefinition;
                        }
                    },
                );
            });

            fieldValue?.map((item, index) => {
                const child_data = this.checkTriggerStateToUse(
                    current,
                    item[SECOND_NAME],
                    action,
                );

                child_data.forEach((child) => {
                    if (child?.name) {
                        const UPDATE_FIELD_NAME = child.name.split('.');

                        child = {
                            ...child,
                            name: UPDATE_FIELD_NAME[1],
                        };

                        (children[index] || (children[index] = [])).push(child);
                    }
                });
            });

            if (elementLayoutInfo) {
                data.push({
                    name: FIRST_NAME,
                    ...elementLayoutInfo,
                    children,
                });
            }
        } else {
            data = this.checkTriggerStateToUse(current, fieldValue, action);
        }

        return data;
    }

    checkTriggerStateToUse(current, fieldValue, action) {
        if (!current?.is) {
            return [];
        }

        return current.is.reduce(
            (_, is) =>
                this.formTriggersService.is(is, fieldValue)
                    ? [..._, ...(is.then?.[action] ? is.then[action] : [])]
                    : [..._, ...(is.else?.[action] ? is.else[action] : [])],
            [],
        );
    }

    onClickEvent(name: string): void {
        this.clmiOnClick.emit({ name });
    }

    trackBySection(index: number, section: FormSectionLayoutDefinition) {
        return section.name;
    }

    trackByElement(index: number, element: FieldDefinition) {
        return element.name;
    }
}
