import { GridOptions } from '@ag-grid-community/core';

export const SERVER_SIDE_DEFAULT_GRID_OPTIONS: GridOptions = {
    rowModelType: 'serverSide',
    pagination: true,
    paginationPageSize: 50,
    cacheBlockSize: 50,
    suppressMultiSort: true,
    suppressCellFocus: true,
};
