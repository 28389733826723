import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { FileIndex } from '@wdx/shared/utils';

@Component({
    selector: 'wdx-file-icon',
    standalone: true,
    imports: [CommonModule, WdxIconModule],
    templateUrl: './wdx-file-icon.component.html',
    styleUrls: ['./wdx-file-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxFileIconComponent {
    @Input() fileIndex?: FileIndex;

    get fileType() {
        return this.fileIndex?.name?.split('.').pop();
    }

    get icon() {
        switch (this.fileType) {
            case 'docx':
                return 'file-word';
            case 'pdf':
                return 'file-pdf';
            case 'xls':
                return 'file-excel';
            case 'jpeg':
                return 'file-image';
            case 'png':
                return 'file-image';
            default:
                return 'file';
        }
    }
}
