import {
    ChangeDetectionStrategy,
    Component,
    Input,
    inject,
} from '@angular/core';
import { ImportApiService } from '@wdx/clmi/api-services/services';
import {
    DOWNLOAD,
    UTILS_ICON_CHEVRON_LEFT,
    UTILS_ICON_CHEVRON_RIGHT,
    WdxDestroyClass,
} from '@wdx/shared/utils';
import { Observable, takeUntil, tap } from 'rxjs';
import { ModalManagementService } from '../../../../services/modal-management.service';
import {
    DOWNLOAD_GUIDANCE_VAL,
    DOWNLOAD_VAL,
    IMPORT_ENTITIES_DOWNLOAD_MODAL_ID,
} from '../import-entities.constant';
import { ImportEntitiesFacadeService } from '../services/import-entities-facade.service';
import { ImportCategory } from '@wdx/clmi/api-models';

@Component({
    selector: 'clmi-import-entities-download-modal',
    templateUrl: './import-entities-download-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportEntitiesDownloadModalComponent extends WdxDestroyClass {
    private importEntitiesFacadeService = inject(ImportEntitiesFacadeService);
    private importApiService = inject(ImportApiService);
    private modalManagementService = inject(ModalManagementService);

    @Input() modalTitle: string;
    @Input() actionType: string;
    public selectedCategory: ImportCategory;

    importEntities$: Observable<ImportCategory[]>;
    importEntitiesIsLoading$: Observable<boolean>;
    importEntitiesHasError$: Observable<boolean>;

    readonly IMPORT_ENTITIES_DOWNLOAD_MODAL_ID =
        IMPORT_ENTITIES_DOWNLOAD_MODAL_ID;
    readonly ICON_CHEVRON_LEFT = UTILS_ICON_CHEVRON_LEFT;
    readonly ICON_CHEVRON_RIGHT = UTILS_ICON_CHEVRON_RIGHT;

    fetchData() {
        this.importEntitiesFacadeService.setImportEntitiesList();
        this.importEntities$ =
            this.importEntitiesFacadeService.importEntities$.pipe(
                takeUntil(this.destroyed$),
                tap(
                    (entities) =>
                        !entities &&
                        this.importEntitiesFacadeService.getImportEntities(),
                ),
            );
        this.importEntitiesIsLoading$ =
            this.importEntitiesFacadeService.importEntitiesIsLoading$;
        this.importEntitiesHasError$ =
            this.importEntitiesFacadeService.importEntitiesHasError$;
    }
    onModalClosed(): void {
        this.modalManagementService.closeActiveModal();
    }

    onDownload(entity: string) {
        if (this.actionType === DOWNLOAD_VAL) {
            this.onDownloadTemplate(entity);
        } else if (this.actionType === DOWNLOAD_GUIDANCE_VAL) {
            this.onDownloadGuidance(entity);
        }
    }

    onDownloadTemplate(entity: string): void {
        this.importApiService
            .downloadTemplate$(entity, false)
            .subscribe((blob) => DOWNLOAD(`${entity}-template`, blob));
    }

    onDownloadGuidance(entity: string): void {
        this.importApiService
            .downloadTemplate$(entity, true)
            .subscribe((blob) => DOWNLOAD(`${entity}-guidance`, blob));
    }

    selectCategory(category: ImportCategory): void {
        this.selectedCategory = category;
    }

    onBackToCategoriesList(): void {
        this.selectedCategory = null;
    }
}
