<section class="d-flex">
    <div class="flex-grow-1 me-2">
        <wdx-ff-subform-info
            [entityId]="entityId"
            [fieldDefinition]="elementDefinition"
            [data]="formControlObj?.value"
            [parentData]="parentData"
            [index]="place"
        >
        </wdx-ff-subform-info>
    </div>
    @if (!readonlyMode) {
        <div class="flex-shrink-0 d-flex align-items-center">
            <wdx-icon-button
                *ngIf="!disabled && !statusInfo"
                [attr.data-cy]="'header-remove-button'"
                wdxStyle="outline-primary"
                wdxButton
                [size]="'sm'"
                (wdxOnClick)="removeItemClicked()"
            >
                <wdx-icon [icon]="ICON_REMOVE"></wdx-icon>
            </wdx-icon-button>

            <wdx-badge themeColor="warning" *ngIf="statusInfo">
                <wdx-icon [icon]="ICON_DANGER"></wdx-icon>
                {{ statusInfo }}
            </wdx-badge>
        </div>
    }
</section>
