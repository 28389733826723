@for (avatar of avatars; track avatar.partyId) {
    <wdx-avatar-container
        *ngIf="avatar"
        [popupEnabled]="popupEnabled"
        (click)="avatarClicked(avatar)"
        wdxStopPropagation
    >
        <wdx-avatar
            wdxAvatar
            [avatar]="avatar"
            [size]="'sm'"
            [borderColor]="(avatars?.length || 0) > 1 ? 'light' : undefined"
        ></wdx-avatar>
        <div wdxAvatarPopupContent *ngIf="popupEnabled">
            <wdx-avatar-popup-content
                wdxAvatarPopupContent
                *ngIf="popupContent$ | ngrxPush as content; else loadingTmpl"
                [avatar]="avatar"
                [content]="content"
            ></wdx-avatar-popup-content>
        </div>
    </wdx-avatar-container>
}

<ng-container *ngIf="(avatars?.length || 0) === 1">
    <span
        *ngIf="!url && label"
        class="ms-2"
        [attr.data-cy]="'avatar-cell-label'"
        >{{ label }}</span
    >
    <a
        *ngIf="url"
        class="ms-2"
        [routerLink]="[url]"
        [target]="newTab ? '_blank' : ''"
        [attr.data-cy]="'avatar-cell-link'"
        >{{ label }}</a
    >
</ng-container>

<ng-template #loadingTmpl>
    <div class="w-100 d-flex px-5 py-2 mx-5 my-2">
        <wdx-spinner></wdx-spinner>
    </div>
</ng-template>
