import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { WdxButtonStyle } from '@wdx/shared/components/wdx-button';
import { WdxSplitButtonMenuItem } from '@wdx/shared/components/wdx-split-button';
import {
    FieldDefinitionOption,
    FormActionType,
    FormCompletionSummary,
    FormDataResult,
    FormDataStatus,
    FormDefinition,
    FormFieldType,
    FormInitialisationMode,
    FormLayout,
    FormLock,
    FormSaveMode,
    FormSectionLayoutDefinition,
    FormStateData,
    MenuItem,
    POP_UP_TRIGGER,
    Privilege,
    StringifyComparePipe,
    SummaryLevel,
    TRANSLATION_BY_LABEL,
    TRANSLATION_CANCEL_BTN,
    TRANSLATION_ENUM_FORMDATASTATUS_CONFIRMED,
    TRANSLATION_ENUM_FORMDATASTATUS_DRAFT,
    TRANSLATION_ENUM_FORMDATASTATUS_HISTORICDRAFT,
    TRANSLATION_ENUM_FORMDATASTATUS_HISTORICPUBLISHED,
    TRANSLATION_ENUM_FORMDATASTATUS_PUBLISHED,
    TRANSLATION_FORM_CLICK_HERE_BTN,
    TRANSLATION_FORM_CLICK_TO_VIEW_VERSION_BTN,
    TRANSLATION_FORM_ERROR_COMPLETE_MISSING_FIELDS,
    TRANSLATION_FORM_FIELDS_COMPLETED,
    TRANSLATION_FORM_INFO_VERSION_OF_THIS_FORM,
    TRANSLATION_FORM_INFO_YOU_ARE_VIEWING,
    TRANSLATION_FORM_NO_REQUIRED_FIELDS,
    TRANSLATION_OF_LABEL,
    TRANSLATION_POPOVER_BACK_TO_FORM,
    TRANSLATION_POPOVER_FORM_HISTORY,
    TRANSLATION_SAVE_AND_COMPLETE_BTN,
    TRANSLATION_SAVE_AS_DRAFT_BTN,
    TRANSLATION_SAVE_BTN,
    TRANSLATION_SOMETHING_WENT_WRONG,
    TRANSLATION_FORM_EXTERNAL_USER_FORM_DATA_CHANGES,
    TranslationsService,
    UTILS_ICON_AUDIT,
    UTILS_ICON_CHEVRON_LEFT,
    WdxDestroyClass,
    ExternalTaskStatusType,
} from '@wdx/shared/utils';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DEFAULT_FORM_STYLING } from '../../../constants';
import { ARRAY_CONTROL_TYPES } from '../../../constants/control-types';
import { IFormExternalActions } from '../../../interfaces';
import {
    FormFrameworkDirtyEvent,
    FormFrameworkEvent,
    FormFrameworkFormTouchedEvent,
    FormFrameworkFormValueChangedEvent,
    FormFrameworkInitialisedEvent,
    FormFrameworkSaveClickedEvent,
    FormStylingOptions,
    ValidationSummaryError,
    ValidationSummarySection,
} from '../../../models';
import { FormActionButton } from '../../../models/form-action-button.model';
import {
    FormBuilderService,
    FormConditionsService,
    FormControlService,
    FormLockService,
    FormProcessConditionDataService,
    FormStaticService,
    FormSummaryLayoutService,
    FormValidationService,
} from '../../../services';
import { FormFieldsDataService } from '../../../services/form-fields-data';
import { FormPendingChangesService } from '../../../services/form-pending-changes/form-pending-changes.service';

@Component({
    selector: 'wdx-ff-form',
    templateUrl: './form-framework-form.component.html',
    providers: [
        FormBuilderService,
        FormConditionsService,
        FormValidationService,
        FormStaticService,
        FormProcessConditionDataService,
        FormControlService,
        FormPendingChangesService,
        FormFieldsDataService,
    ],
})
export class FormFrameworkFormComponent
    extends WdxDestroyClass
    implements OnInit, AfterContentInit
{
    public fbService = inject(FormBuilderService);
    public condService = inject(FormConditionsService);
    public formPendingChangesService = inject(FormPendingChangesService);
    private formStaticService = inject(FormStaticService);
    private formSummaryLayoutService = inject(FormSummaryLayoutService);
    private formValidationService = inject(FormValidationService);
    private elementRef = inject(ElementRef);
    private formLockService = inject(FormLockService);
    private externalActionsHandler = inject(IFormExternalActions);
    private formFieldsDataService = inject(FormFieldsDataService);
    protected translationsService = inject(TranslationsService);

    @Input() formId!: string;
    @Input() entityId!: string;
    @Input() appId!: string;
    @Input() formDefinition!: FormDefinition;
    @Input() formLayout!: FormLayout;
    @Input() formData!: FormDataResult;
    @Input() isQuickCreate = false;
    @Input() isLoading!: boolean;
    @Input() hasError!: boolean;
    @Input() isSaving!: boolean;
    @Input() modalInstanceId?: string;
    @Input() set stylingOptions(options: FormStylingOptions) {
        this.formStylingOptions = {
            ...DEFAULT_FORM_STYLING,
            ...options,
        };
    }
    @Input() auditMode = false;

    private _readonlyMode = false;
    @Input() set readonlyMode(value: boolean) {
        this.formStaticService.isReadonlyMode = value;
        this._readonlyMode = value;
    }
    get readonlyMode() {
        return this._readonlyMode;
    }

    @Input() set triggerSave(value: number | undefined) {
        if (value) {
            this.onSaveClick();
        }
    }
    @Input() set triggerSaveDraft(value: number | undefined) {
        if (value) {
            this.onDraftSaveClick();
        }
    }
    @Input() formTouchedByUser = false;
    @Input() extraContext?: Record<string, any>;

    @Output() formTitleReceived = new EventEmitter<string>();
    @Output() cancelClicked = new EventEmitter();
    @Output() saveClicked = new EventEmitter<FormStateData>();
    @Output() saveDraftClicked = new EventEmitter<FormStateData>();
    @Output() completionSummaryChanged =
        new EventEmitter<FormCompletionSummary>();
    @Output() formEvent = new EventEmitter<FormFrameworkEvent>();
    @Output() formFunction = new EventEmitter<{
        formFunction: string;
        formData: Record<string, any>;
    }>();

    @ViewChild('accordion', { read: NgbAccordionDirective })
    accordion!: NgbAccordionDirective;

    @HostBinding('class') class = 'd-flex flex-column h-100 overflow-hidden';

    validationSummary!: ValidationSummarySection[];
    completionSummary!: FormCompletionSummary;
    formStylingOptions!: FormStylingOptions;

    form!: UntypedFormGroup;
    sectionsInError!: string[];

    fullFormLock!: boolean;
    saveMode!: boolean;
    saveCompleteMode!: boolean;
    saveDraftMode!: boolean;
    isDraftForm!: boolean;
    isPublishedForm!: boolean;
    showFormSwitcherPanel!: boolean;
    showFormHistory = false;

    draftForm!: FormDataResult;
    publishedForm!: FormDataResult;
    validationMessage!: string;
    activePanelIds!: string[];
    submitAttempted = false;
    WdxButtonStyle = WdxButtonStyle;

    get summaryInfoCards$(): Observable<FieldDefinitionOption[]> {
        return this.formSummaryLayoutService.summaryInfoCards$;
    }

    readonly ExternalTaskStatusType = ExternalTaskStatusType;
    readonly FORM_FIELD_TYPE = FormFieldType;
    readonly ARRAY_CONTROL_TYPES = ARRAY_CONTROL_TYPES;
    readonly PRIVILEGE = Privilege;
    readonly FORM_DATA_STATUS = FormDataStatus;
    readonly POP_UP_TRIGGER = POP_UP_TRIGGER;
    readonly ICON_FORM_HISTORY = UTILS_ICON_AUDIT;
    readonly ICON_BACK = UTILS_ICON_CHEVRON_LEFT;
    readonly POPOVER_FORM_HISTORY = TRANSLATION_POPOVER_FORM_HISTORY;
    readonly POPOVER_BACK_TO_FORM = TRANSLATION_POPOVER_BACK_TO_FORM;
    readonly SOMETHING_WENT_WRONG = TRANSLATION_SOMETHING_WENT_WRONG;
    readonly SAVE_AS_DRAFT_BTN = TRANSLATION_SAVE_AS_DRAFT_BTN;
    readonly CANCEL_BTN = TRANSLATION_CANCEL_BTN;
    readonly SAVE_AND_COMPLETE_BTN = TRANSLATION_SAVE_AND_COMPLETE_BTN;
    readonly SAVE_BTN = TRANSLATION_SAVE_BTN;
    readonly NO_REQUIRED_FIELDS = TRANSLATION_FORM_NO_REQUIRED_FIELDS;
    readonly OF_LABEL = TRANSLATION_OF_LABEL;
    readonly FIELDS_COMPLETED = TRANSLATION_FORM_FIELDS_COMPLETED;
    readonly CLICK_HERE_BTN = TRANSLATION_FORM_CLICK_HERE_BTN;
    readonly CLICK_TO_VIEW_VERSION_BTN =
        TRANSLATION_FORM_CLICK_TO_VIEW_VERSION_BTN;
    readonly FORM_INFO_YOU_ARE_VIEWING = TRANSLATION_FORM_INFO_YOU_ARE_VIEWING;
    readonly BY = TRANSLATION_BY_LABEL;
    readonly VERSION_OF_THIS_FORM = TRANSLATION_FORM_INFO_VERSION_OF_THIS_FORM;
    readonly FORM_EXTERNAL_USER_FORM_DATA_CHANGES =
        TRANSLATION_FORM_EXTERNAL_USER_FORM_DATA_CHANGES;

    splitButtonMenuItems: MenuItem[] = [
        {
            label: this.translationsService.getTranslationByKey(
                this.SAVE_AS_DRAFT_BTN,
            ) as any,
        },
    ];

    get messageDefinition() {
        return this.formFieldsDataService?.messageDefinitionToShow;
    }

    get messageDefinitionLength() {
        return Object.keys(this.formFieldsDataService?.messageDefinitionToShow)
            ?.length;
    }

    ngOnInit() {
        this.setupForm();
        this.setModes();
        this.setUpSummary();
        this.setDraftModeProps();
        this.formPendingChangesService.setUpPendingChanges(
            this.condService.layoutAndDefinition,
            this.fbService.firstLevel,
            this.formStaticService?.formData?.pendingChanges,
        );

        this.validationMessage = this.saveDraftMode
            ? this.translationsService.getTranslationByKey(
                  TRANSLATION_FORM_ERROR_COMPLETE_MISSING_FIELDS,
              )
            : this.translationsService.getTranslationByKey(
                  TRANSLATION_FORM_ERROR_COMPLETE_MISSING_FIELDS,
              );

        this.formStaticService.form.valueChanges
            .pipe(
                distinctUntilChanged((x, y) =>
                    new StringifyComparePipe().transform(x, y),
                ),
                takeUntil(this.formStaticService.destroyed$),
                debounceTime(300),
            )
            .subscribe((value) => {
                if (!this.readonlyMode) {
                    this.formEvent.emit(
                        new FormFrameworkFormValueChangedEvent({
                            dirty: this.form.dirty,
                            pristine: this.form.pristine,
                            touched: this.form.touched,
                            valid:
                                !this.form.invalid &&
                                !this.validationSummary?.length,
                            formValue: value,
                        }),
                    );
                    if (this.form.dirty) {
                        this.formEvent.emit(new FormFrameworkDirtyEvent({}));
                    }
                }
            });

        if (this.isQuickCreate) {
            this.externalActionsHandler.handleQuickCreate();
        }
    }

    ngAfterContentInit() {
        this.formEvent.emit(
            new FormFrameworkInitialisedEvent({
                dirty: this.form.dirty,
                pristine: this.form.pristine,
                touched: this.form.touched,
                valid: !this.form.invalid && !this.validationSummary?.length,
                formValue: this.form.value,
            }),
        );
        this.showFormVersionSwitcher();
        this.storeFormSwitchData();
    }

    setupForm(forceFormLock = false): void {
        this.formTitleReceived.emit(this?.formLayout?.label);
        this.formStaticService.formDefinition = this.formDefinition;
        this.formStaticService.formLayout = this.formLayout;
        this.formStaticService.formData = this.formData;
        this.formStaticService.formId = this.formId;

        this.formStaticService.initialisationMode = this.formData?.entityId
            ? FormInitialisationMode.Edit
            : FormInitialisationMode.Add;
        this.fbService.buildForm(this.extraContext);
        this.form = this.formStaticService.form;

        this.condService.configService(
            this.formStaticService.form,
            this.formStaticService.formDefinition,
            this.formStaticService.formData,
            this.formStaticService.formId,
            this.formStaticService.fieldsToNullCheck,
            this.formStaticService.initialisationMode,
        );

        this.activePanelIds = Array.from(
            { length: this.condService.layoutAndDefinition?.length },
            (_, i) => `reactive-form-${i}`,
        );

        this.fullFormLock = this.getFullFormLock(
            this.formData?.lock as FormLock,
            forceFormLock,
        );

        if (this.formData.lock || forceFormLock) {
            this.applyFormLock(this.formData.lock as FormLock, forceFormLock);
        }

        this.calculateValidationSummary();

        this.formStaticService.form.statusChanges
            .pipe(takeUntil(this.destroyed$), debounceTime(200))
            .subscribe(() => this.calculateValidationSummary());
    }

    getFullFormLock(lock: FormLock, forceFormLock = false) {
        return forceFormLock || this.formLockService.hasFullFormLock(lock);
    }

    /**
     * Apply form lock by disabling the entire or individual form fields
     */
    applyFormLock(lock: FormLock, forceFormLock = false): void {
        // Update the models in condition service (so lock status can be referenced from definition)
        this.condService.updateLockStatuses(forceFormLock);

        // Check for form lock status and disable entire form if true
        if (this.formLockService.hasFullFormLock(lock) || forceFormLock) {
            this.formStaticService.form.disable();
            return;
        }

        // For partial locks, disable the associated form controls
        if (this.formLockService.hasPartialFormLock(lock)) {
            lock.fields?.forEach((field) => {
                const controlToLock = this.formStaticService?.form?.get(field);
                if (controlToLock) {
                    controlToLock.disable();
                }
            });
        }
    }

    setUpSummary() {
        this.formSummaryLayoutService.getSummaryInfoCards(
            this.formDefinition,
            this.formStaticService.form.getRawValue(),
        );

        this.formStaticService.formDefinition.schema
            ?.filter((item) => item.summaryLevel === SummaryLevel.InfoCard)
            .forEach((summaryField) => {
                this.formStaticService.form?.controls[
                    summaryField.name as string
                ]?.valueChanges.subscribe(() => {
                    this.formSummaryLayoutService.getSummaryInfoCards(
                        this.formDefinition,
                        this.formStaticService.form.getRawValue(),
                    );
                });
            });
    }

    setModes(): void {
        this.saveMode = !!this.formDefinition?.saveModes?.includes(
            FormSaveMode.Save,
        );

        this.saveCompleteMode = !!this.formDefinition?.saveModes?.includes(
            FormSaveMode.Complete,
        );
    }

    setDraftModeProps(): void {
        this.saveDraftMode = !!this.formDefinition?.saveModes?.includes(
            FormSaveMode.Draft,
        );
        this.isDraftForm =
            this.formData?.status === this.FORM_DATA_STATUS.Draft;
        this.isPublishedForm =
            this.formData?.status === this.FORM_DATA_STATUS.Published;
    }

    showFormVersionSwitcher(): void {
        this.showFormSwitcherPanel =
            this.saveDraftMode &&
            this.isDraftForm &&
            this.formData?.lastPublishedData;
    }

    storeFormSwitchData(): void {
        if (this.formData?.status === FormDataStatus.Draft) {
            this.draftForm = this.formData;
        }

        if (this.formData?.lastPublishedData) {
            this.publishedForm = this.formData.lastPublishedData;
        }
    }

    trackSection(_: any, section: FormSectionLayoutDefinition) {
        return section.name;
    }

    trackElement(_: number, layout: any) {
        return layout.name;
    }

    trackByMessage(_: any, messageDefinition: any): string {
        return messageDefinition?.key;
    }

    onFormAction(
        formActionButton: FormActionButton | WdxSplitButtonMenuItem,
    ): void {
        switch (formActionButton.type) {
            case 'Cancel':
                this.onCancelClick();
                break;
            case FormActionType.Complete:
                this.onSaveClick('complete=true');
                break;
            case FormActionType.Function:
                this.onFormFunctionClick(formActionButton.action?.function);
                break;
            case FormActionType.Publish:
                this.onSaveClick();
                break;
            case FormActionType.SaveDraft:
                this.onDraftSaveClick();
                break;
        }
    }

    /**
     * When form is cancelled, reset form to initial state
     */
    onCancelClick(): void {
        if (this.isQuickCreate) {
            this.formStaticService.form.reset();
        }
        this.cancelClicked.emit();
    }

    switchFormVersion() {
        const formStatus: FormDataStatus = this.formData
            ?.status as FormDataStatus;

        if (formStatus === FormDataStatus.Draft) {
            this.formData = this.publishedForm;
            this.condService.reset();
            this.setupForm(true);
            this.setDraftModeProps();
        } else if (formStatus === FormDataStatus.Published) {
            this.formData = this.draftForm;
            this.condService.reset();
            this.setupForm(false);
            this.setDraftModeProps();
        }
    }

    onSaveClick(queryParams?: string): void {
        if (this.formStaticService.form.invalid) {
            if (!this.formStaticService.submitAttempted$.isStopped) {
                this.formStaticService.form.markAllAsTouched();
                this.formStaticService.form.updateValueAndValidity();
                this.formStaticService.submitAttempted$.next(true);
                this.formStaticService.submitAttempted$.complete();
                this.submitAttempted = true;
            }

            this.formEvent.emit(
                new FormFrameworkSaveClickedEvent({ valid: false }),
            );
            return;
        }

        this.formEvent.emit(new FormFrameworkSaveClickedEvent({ valid: true }));

        if (this.formStaticService.form.valid) {
            this.saveClickEmit(queryParams as string);
        }
    }

    saveClickEmit(queryParams: string): void {
        this.completionSummaryChanged.emit(this.completionSummary);
        this.saveClicked.emit({
            value: this.formFieldsDataService.parseFormData(
                this.formStaticService.fieldsToNullCheck,
                this.formStaticService.form,
            ),
            queryParams: queryParams,
        });
    }

    onDraftSaveClick(): void {
        this.completionSummaryChanged.emit(this.completionSummary);
        this.saveDraftClicked.emit({
            value: this.formFieldsDataService.parseFormData(
                this.formStaticService.fieldsToNullCheck,
                this.formStaticService.form,
            ),
        });
    }

    onFormFunctionClick(formFunction?: string): void {
        if (!formFunction) {
            return;
        }
        this.formFunction.emit({
            formFunction,
            formData: this.formFieldsDataService.parseFormData(
                this.formStaticService.fieldsToNullCheck,
                this.formStaticService.form,
            ),
        });
    }

    calculateValidationSummary() {
        const validationSummary =
            this.formValidationService.getValidationSummary(
                this.condService.layoutAndDefinition,
                this.formStaticService.form.controls,
            );

        this.validationSummary = validationSummary.sections.filter(
            (section) => section?.errors?.length > 0,
        );

        this.completionSummary =
            this.formValidationService.getCompletionSummary(
                this.formStaticService.form,
            );

        this.sectionsInError = this.validationSummary.map(
            (section) => section.name,
        ) as string[];
    }

    onErrorClicked(error: ValidationSummaryError): void {
        this.accordion?.expand(`reactive-form-${error.sectionIndex}`);

        // delay scroll to allow accordion to expand first
        setTimeout(() => {
            const fieldDefinitionElementRef =
                this.elementRef.nativeElement.querySelector(
                    `[data-form-control='${error.name}']`,
                );
            this.formValidationService.scrollToError(fieldDefinitionElementRef);
        }, 100);
    }

    openLinkInNewTab(entity: string, id?: string): void {
        this.externalActionsHandler.handleOpenInNewTab(entity, id);
    }

    toggleFormHistory(): void {
        this.showFormHistory = !this.showFormHistory;
    }

    get formDataStatus(): string {
        const formDataStatus = this.formData?.status;

        switch (formDataStatus) {
            case FormDataStatus.Draft:
                return TRANSLATION_ENUM_FORMDATASTATUS_DRAFT;
            case FormDataStatus.Published:
                return TRANSLATION_ENUM_FORMDATASTATUS_PUBLISHED;
            case FormDataStatus.Confirmed:
                return TRANSLATION_ENUM_FORMDATASTATUS_CONFIRMED;
            case FormDataStatus.HistoricDraft:
                return TRANSLATION_ENUM_FORMDATASTATUS_HISTORICDRAFT;
            case FormDataStatus.HistoricPublished:
                return TRANSLATION_ENUM_FORMDATASTATUS_HISTORICPUBLISHED;

            default:
                return formDataStatus ?? '';
        }
    }

    formElementClicked(event: Event) {
        if (this.formTouchedByUser) {
            return;
        }
        this.formTouchedByUser = true;
        this.formEvent.emit(
            new FormFrameworkFormTouchedEvent({
                dirty: this.form.dirty,
                pristine: this.form.pristine,
                touched: this.form.touched,
                valid: !this.form.invalid && !this.validationSummary?.length,
                formValue: this.form.value,
                event,
            }),
        );
    }
}
