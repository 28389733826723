import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortCurrency', standalone: true })
export class ShortCurrencyPipe implements PipeTransform {
    transform(value: number, currencyCode: string, precision?: number): string {
        if (!value && value !== 0) {
            return '';
        }

        if (typeof value !== 'number') {
            return value;
        }

        const oneMillion = 1000000;
        const tenThousand = 10000;
        const oneThousand = 1000;
        const oneHundred = 100;
        const one = 1;

        let resultValue: number;
        let resultUnit: string;

        switch (true) {
            case value >= oneMillion:
                resultUnit = 'm';
                resultValue =
                    this.parseNumber(value, tenThousand, precision) /
                    oneHundred;
                break;

            case value >= oneThousand:
                resultUnit = 'k';
                resultValue = this.parseNumber(value, oneThousand, precision);
                break;

            default:
                resultUnit = '';
                resultValue = this.parseNumber(value, one, precision);
                break;
        }

        const currencySymbol = getCurrencySymbol(currencyCode, 'narrow');

        return `${currencySymbol}${
            currencyCode === currencySymbol ? ' ' : ''
        }${resultValue}${resultUnit}`;
    }

    parseNumber(value: number, round: number, precision?: number): number {
        if (precision) {
            return parseFloat(value.toPrecision(precision)) / round;
        } else {
            return Math.round(value / round);
        }
    }
}
