import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    inject,
} from '@angular/core';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { PushPipe } from '@ngrx/component';
import {
    WdxButtonModule,
    WdxButtonStyle,
} from '@wdx/shared/components/wdx-button';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxDialogService } from './wdx-dialog.service';

@Component({
    selector: 'wdx-dialog',
    templateUrl: './wdx-dialog.component.html',
    styleUrls: ['./wdx-dialog.component.scss'],
    imports: [
        CommonModule,
        WdxButtonModule,
        NgbModalModule,
        WdxIconModule,
        PushPipe,
    ],
    providers: [NgbActiveModal],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxDialogComponent {
    @Input() title!: string;
    @Input() dialogBody?: string;
    @Input() defaultButtons = true;
    @Input() cancelButtonLabel = 'No';
    @Input() actionButtonLabel = 'Yes';
    @Input() actionButtonStyle: WdxButtonStyle = WdxButtonStyle.OutlinePrimary;
    @Input() cancelButtonStyle: WdxButtonStyle | '' = '';
    @Input() dialogStyling: {
        horizontalRule: boolean;
    } = {
        horizontalRule: true,
    };

    @Output() wdxOnClick = new EventEmitter();
    @Output() cancelButtonClick = new EventEmitter();
    @Output() dismissClick = new EventEmitter();

    public wdxDialogService = inject(WdxDialogService);
    public WdxButtonStyle = WdxButtonStyle;

    public cancelButtonClicked() {
        this.cancelButtonClick.emit();
        this.wdxDialogService.dismiss();
    }

    public actionClick(): void {
        this.wdxOnClick.emit();
        this.wdxDialogService.close();
    }

    public dismissClicked(): void {
        this.dismissClick.emit();
        this.wdxDialogService.dismiss();
    }
}
