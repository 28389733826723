<wdx-icon
    *ngIf="icon"
    [icon]="icon"
    [iconStyle]="iconStyle"
    class="me-1"
    [ngClass]="iconClasses"
></wdx-icon>
<span [ngClass]="labelClasses" [attr.data-cy]="'icon-label-label'">
    {{ label }}
</span>
