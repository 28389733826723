import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ICellRendererParams } from '@ag-grid-community/core';
import { WdxFileIconComponent } from '@wdx/shared/components/wdx-file-icon';
import { FileIndex } from '@wdx/shared/utils';

export interface FileIconRendererValue {
    label?: string;
    fileIndex?: FileIndex;
}

@Component({
    selector: 'wdx-ag-grid-file-icon-cell-renderer',
    standalone: true,
    imports: [CommonModule, RouterModule, WdxFileIconComponent],
    templateUrl: './file-icon-renderer.component.html',
})
export class FileIconRendererComponent implements ICellRendererAngularComp {
    public label?: string;
    public fileIndex?: FileIndex;

    public agInit(
        params: ICellRendererParams<any, FileIconRendererValue>,
    ): void {
        if (params?.value) {
            this.label = params.value.label;
            this.fileIndex = params.value.fileIndex;
        }
    }

    public refresh(
        params: ICellRendererParams<any, FileIconRendererValue>,
    ): boolean {
        this.agInit(params);
        return true;
    }
}
