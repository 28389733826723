import { CellClassParams } from '@ag-grid-community/core';

export function numberColorCellClass(
    baseClasses?: string,
    field?: string,
    property?: string
) {
    return (params: CellClassParams) => {
        field = field || params.column.getColDef().field;
        baseClasses = baseClasses || '';
        if (!field || !params.data) {
            return baseClasses;
        }
        const value = property
            ? params.data[field]?.[property]
            : params.data[field];
        if (!value || typeof value !== 'number') {
            return baseClasses;
        }
        return value > 0
            ? `text-success ${baseClasses}`
            : `text-danger ${baseClasses}`;
    };
}
