<div class="h-100 d-flex flex-column">
    <div *ngIf="showSearch" class="flex-shrink-0">
        <molecule-search-and-filter-bar
            [searchType]="searchType"
            [searchTypePlural]="searchTypePlural"
            [placeholder]="searchPlaceholder"
            [totalRecordCount]="table | fn: getResultsCount : tablePaging"
            [filterHandler]="filterHandler"
            [filterFormId]="filterFormId"
            [inputSearchText]="filterHandler && filterHandler.getSearchText()"
            [sortMenu]="table | fn: getSortMenu"
            [isLoading]="isLoading"
            [showSearchBar]="showSearchBar"
            [hasMobileFilter]="hasMobileFilter"
            [hasFilterSearch]="hasFilterSearch"
            (search)="onSearch($event)"
            (sort)="onSort($event)"
        >
            <ng-content
                prefixActionButtons
                select="[prefixActionButtons]"
            ></ng-content>

            <div *ngIf="legend" class="legend">
                <div class="divider-start divider--short">
                    <molecule-legend
                        [items]="legend"
                        [hasContainer]="false"
                    ></molecule-legend>
                </div>
            </div>
            <ng-content actionButtons select="[actionButtons]"></ng-content>

            <ng-container actionButtons *ngIf="searchBarActionButtons?.length">
                <molecule-action-button
                    *ngFor="
                        let actionButton of searchBarActionButtons;
                        let first = first;
                        let last = last
                    "
                    btnClass="btn-primary"
                    size="md"
                    [filterHandler]="filterHandler"
                    [class.ms-lg-2]="first"
                    [class.me-lg-2]="!last"
                    [actionButton]="actionButton"
                    [isDisabled]="actionButton?.isDisabled"
                    (onClick)="onActionButtonClicked($event, actionButton)"
                >
                    <div *ngIf="actionButton.label" class="action-button-add">
                        {{ actionButton.label }}
                    </div>
                </molecule-action-button>
            </ng-container>
        </molecule-search-and-filter-bar>
        <hr />
    </div>

    <div
        *ngIf="!infinityScrollFired.observers.length && tablePaging"
        class="d-flex align-items-center justify-content-between flex-shrink-0 body-text-medium mb-3"
    >
        <div class="d-flex align-items-center">
            <span>{{ SHOW_LABEL | translate }}</span>
            <molecule-context-menu-dropdown
                class="d-block mx-2"
                btnClass="btn"
                [menu]="pageLengthMenu"
                [initialValue]="pageLengthMenuDefaultValue"
            ></molecule-context-menu-dropdown>
            <span>{{ RESULTS_LABEL | translate }}</span>
        </div>

        <div>
            @if (tablePaging?.totalRecords) {
                {{ SHOWING_LABEL | translate }}
                {{
                    tablePaging?.page
                        | fn: getResultCountFrom : tablePaging?.pageSize
                }}
                {{ TO_LABEL | translate }}
                {{
                    tablePaging?.page
                        | fn
                            : getResultCountTo
                            : tablePaging?.pageSize
                            : tablePaging?.totalRecords
                }}
                {{ OF_LABEL | translate }}
            }
            {{ tablePaging?.totalRecords ?? 0 }}
            {{ RESULTS_LABEL | translate }}
        </div>
    </div>

    <div
        #scrollContainer
        class="flex-grow-1 position-relative overflow-auto"
        data-cy="list-client"
        [clmiInfiniteScroll]="table?.pagingType === PAGING_TYPE?.InfiniteScroll"
        (scrolled)="onInfinityScrollFired()"
    >
        <atom-async-data
            [isLoading]="isLoading"
            [hasError]="hasError"
            [isForInfinityScroll]="
                table?.pagingType === PAGING_TYPE?.InfiniteScroll &&
                infinityScrollFired?.observers?.length &&
                table?.rows?.length
            "
            [overlayContent]="
                table?.pagingType !== PAGING_TYPE?.InfiniteScroll && tablePaging
            "
        >
            <ng-container *ngIf="table?.rows?.length; else noData">
                <div
                    *ngIf="key && key?.length"
                    [ngClass]="{
                        'table-key--responsive content-separation-border content-separation-border-lg-0 mb-lg-2':
                            'displayMode === TABLE_DISPLAY_MODE.ResponsiveTable',
                        'table-key--table mb-2':
                            'displayMode === TABLE_DISPLAY_MODE.Table',
                    }"
                    [class.table__key--responsive]="
                        displayMode === TABLE_DISPLAY_MODE?.ResponsiveTable
                    "
                    [class.table__key--table]="
                        displayMode === TABLE_DISPLAY_MODE?.Table
                    "
                    [class.table__key--small]="isSmall"
                >
                    <molecule-legend
                        class="overline"
                        label="Key:"
                        [items]="key"
                        [hasContainer]="false"
                        [responsiveOrientation]="false"
                    ></molecule-legend>
                </div>

                <ng-container *ngIf="isMobileView$ | ngrxPush">
                    <ng-container
                        *ngIf="
                            displayMode === TABLE_DISPLAY_MODE?.ResponsiveCards
                        "
                    >
                        <ng-container *ngFor="let card of tableRows">
                            <ng-container
                                *ngIf="card.responsiveCard?.templateRef"
                            >
                                <ng-container
                                    *ngTemplateOutlet="
                                        card.responsiveCard.templateRef;
                                        context: {
                                            data: card.responsiveCard.data,
                                        }
                                    "
                                ></ng-container>
                            </ng-container> </ng-container
                    ></ng-container>

                    <table
                        *ngIf="
                            displayMode === TABLE_DISPLAY_MODE?.ResponsiveTable
                        "
                        class="table table-sm"
                    >
                        <ng-container
                            *ngFor="
                                let card of tableRows;
                                let firstRow = first;
                                let cardIndex = index
                            "
                        >
                            <ng-container
                                *ngIf="
                                    !rowLimit ||
                                    (rowLimit &&
                                        (rowLimit <= 0 ||
                                            (rowLimit > 0 &&
                                                cardIndex < rowLimit)))
                                "
                            >
                                <tbody
                                    [class.card-selectable]="
                                        canSelectRows || canToggleRows
                                    "
                                    (click)="onRowClicked(card)"
                                >
                                    <tr *ngIf="canSelectRows">
                                        <th
                                            *ngIf="table.headers?.length"
                                            class="pt-2 border-0"
                                            scope="row"
                                        ></th>
                                        <td
                                            class="d-flex justify-content-end border-0 me-1"
                                        >
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    rowSelector;
                                                    context: {
                                                        row: card,
                                                        index: cardIndex,
                                                    }
                                                "
                                            ></ng-container>
                                        </td>
                                    </tr>
                                    <tr *ngIf="canToggleRows && !canSelectRows">
                                        <th
                                            *ngIf="table.headers?.length"
                                            class="pt-2 border-0"
                                            scope="row"
                                        ></th>
                                        <td
                                            class="d-flex justify-content-end border-0 pt-2 me-1"
                                        >
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    rowToggle;
                                                    context: { row: card }
                                                "
                                            ></ng-container>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let value of card.values;
                                            let valueIndex = index;
                                            let firstValue = first;
                                            let lastValue = last
                                        "
                                    >
                                        <th
                                            *ngIf="table.headers?.length"
                                            class="border-0 text-nowrap pr-2"
                                            scope="row"
                                            [class.pt-2]="
                                                firstValue && !canSelectRows
                                            "
                                            [class.pb-2]="lastValue"
                                        >
                                            {{
                                                table.headers[valueIndex]?.value
                                                    | truncateText: 16
                                            }}
                                        </th>
                                        <td
                                            class="border-0"
                                            [class.pt-2]="
                                                firstValue && !canSelectRows
                                            "
                                            [class.pb-2]="lastValue"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <ng-container
                                                    *ngTemplateOutlet="
                                                        tableValue;
                                                        context: {
                                                            value: value,
                                                            avatarSize: 'sm',
                                                        }
                                                    "
                                                ></ng-container>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="
                                            card.toggleRowTemplate?.visible &&
                                            !canSelectRows
                                        "
                                        class="bg-gray-100"
                                    >
                                        <td
                                            class="bg-gray-100"
                                            [attr.colspan]="
                                                table?.headers?.length + 1
                                            "
                                        >
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    card.toggleRowTemplate
                                                        .templateRef;
                                                    context: {
                                                        data: card
                                                            .toggleRowTemplate
                                                            .data,
                                                    }
                                                "
                                            ></ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </ng-container>
                        </ng-container>
                    </table>
                </ng-container>

                <ng-container *ngIf="!(isMobileView$ | ngrxPush)">
                    <table
                        data-cy="table-client"
                        class="table"
                        [class.table-hover]="hoverableTable"
                        [class.table-sm]="
                            isSmall || (responsiveIsSmall$ | ngrxPush)
                        "
                    >
                        <ng-container *ngIf="table.headers?.length">
                            <thead>
                                <tr>
                                    <th
                                        *ngIf="canSelectRows || canToggleRows"
                                        class="table__select-head"
                                        role="col"
                                    >
                                        <wdx-checkbox
                                            [checked]="
                                                selectedRows.length ===
                                                table.rows?.length
                                            "
                                            [indeterminate]="
                                                selectedRows.length > 0 &&
                                                selectedRows.length <
                                                    table.rows?.length
                                            "
                                            (isChecked)="selectAllRows()"
                                        ></wdx-checkbox>
                                    </th>
                                    <ng-container
                                        *ngFor="
                                            let header of table.headers;
                                            let headingColIndex = index
                                        "
                                    >
                                        <th
                                            role="col"
                                            [id]="header.id || ''"
                                            [ngClass]="{
                                                'd-none d-lg-table-cell':
                                                    hideColumnIndexesOnSmallScreen.length >
                                                        0 &&
                                                    hideColumnIndexesOnSmallScreen.includes(
                                                        headingColIndex
                                                    ),
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                                [class.pointer]="
                                                    header.sortByFieldName
                                                "
                                                (click)="onHeaderClick(header)"
                                            >
                                                <div
                                                    *ngIf="
                                                        header.sortByFieldName
                                                    "
                                                    class="ms-n1 me-1 d-flex"
                                                >
                                                    <ng-container
                                                        *ngIf="
                                                            filterHandler?.getSortBy() ===
                                                                header.sortByFieldName;
                                                            else notSortedTemplate
                                                        "
                                                    >
                                                        <wdx-icon
                                                            *ngIf="
                                                                filterHandler?.getSortDirection() ===
                                                                SORT_DIRECTION.Ascending
                                                            "
                                                            class="align-items-center d-flex icon-container-size-md justify-content-center"
                                                            icon="chevron-up"
                                                        ></wdx-icon>

                                                        <wdx-icon
                                                            *ngIf="
                                                                filterHandler?.getSortDirection() ===
                                                                SORT_DIRECTION.Descending
                                                            "
                                                            class="align-items-center d-flex icon-container-size-md justify-content-center"
                                                            icon="chevron-down"
                                                        ></wdx-icon>
                                                    </ng-container>
                                                    <ng-template
                                                        #notSortedTemplate
                                                    >
                                                        <wdx-icon-stack
                                                            class="pt-3"
                                                            icon
                                                        >
                                                            <wdx-icon
                                                                *ngFor="
                                                                    let icon of DEFAULT_SORT_ICON
                                                                "
                                                                class="align-items-center d-flex justify-content-center"
                                                                [scale]="0.6"
                                                                [icon]="icon"
                                                            ></wdx-icon>
                                                        </wdx-icon-stack>
                                                    </ng-template>
                                                </div>
                                                <span>{{
                                                    header.translationKey
                                                        ? (header.translationKey
                                                          | translate)
                                                        : header.value
                                                }}</span>
                                            </div>
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                        </ng-container>

                        <tbody>
                            <ng-container
                                *ngFor="
                                    let row of tableRows;
                                    let rowIndex = index
                                "
                            >
                                <ng-container
                                    *ngIf="
                                        !rowLimit ||
                                        (rowLimit &&
                                            (rowLimit <= 0 ||
                                                (rowLimit > 0 &&
                                                    rowIndex < rowLimit)))
                                    "
                                >
                                    <tr
                                        *ngIf="!row.formSetup"
                                        [ngClass]="{
                                            'router-link-row':
                                                row.routerLink ||
                                                canSelectRows ||
                                                canToggleRows,
                                            selected:
                                                row.toggleRowTemplate
                                                    ?.visible ||
                                                (selectedRows
                                                    | fn: rowIsSelected : row),
                                        }"
                                        [attr.draggable]="canDragRows"
                                        (dragstart)="dragstart(rowIndex)"
                                        (dragenter)="dragenter($event.target)"
                                        (dragleave)="
                                            dragleave($event.target, rowIndex)
                                        "
                                        (dragover)="dragover($event)"
                                        (dragend)="dragend()"
                                        (click)="onRowClicked(row)"
                                        data-cy="table-row"
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="
                                                rowTemplate;
                                                context: { row: row }
                                            "
                                        ></ng-container>
                                    </tr>
                                    <tr
                                        *ngIf="row.formSetup"
                                        class="router-link-row"
                                        [dynamicFormSetup]="row.formSetup"
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="
                                                rowTemplate;
                                                context: { row: row }
                                            "
                                        ></ng-container>
                                    </tr>

                                    <tr
                                        *ngIf="
                                            row.toggleRowTemplate?.visible &&
                                            !canSelectRows
                                        "
                                        class="bg-gray-100"
                                    >
                                        <td
                                            class="bg-gray-100 p-0"
                                            [attr.colspan]="
                                                table?.headers?.length + 1
                                            "
                                        >
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    row.toggleRowTemplate
                                                        .templateRef;
                                                    context: {
                                                        data: row
                                                            .toggleRowTemplate
                                                            .data,
                                                    }
                                                "
                                            ></ng-container>
                                        </td>
                                    </tr>

                                    <ng-template #rowTemplate let-row="row">
                                        <td *ngIf="canDragRows" role="col">
                                            <div class="drag-handle">
                                                <wdx-icon
                                                    class="icon-container d-flex justify-content-center align-items-center icon-container-size-lg"
                                                    icon="grip-vertical"
                                                ></wdx-icon>
                                                <div
                                                    class="drag-handle-overlay"
                                                ></div>
                                            </div>
                                        </td>
                                        <ng-container
                                            *ngFor="
                                                let value of row.values;
                                                let colIndex = index;
                                                let first = first
                                            "
                                        >
                                            <ng-container
                                                *ngIf="canSelectRows && first"
                                            >
                                                <td
                                                    [ngClass]="{
                                                        'd-none d-lg-table-cell':
                                                            hideColumnIndexesOnSmallScreen.length >
                                                                0 &&
                                                            hideColumnIndexesOnSmallScreen.includes(
                                                                colIndex
                                                            ),
                                                    }"
                                                >
                                                    <ng-container
                                                        *ngTemplateOutlet="
                                                            rowSelector;
                                                            context: {
                                                                row: row,
                                                                index: rowIndex,
                                                            }
                                                        "
                                                    ></ng-container>
                                                </td>
                                            </ng-container>
                                            <th
                                                *ngIf="
                                                    colIndex === 0 &&
                                                        highlightFirstColumn;
                                                    else defaultRow
                                                "
                                                scope="row"
                                                [ngClass]="{
                                                    'd-none d-lg-table-cell':
                                                        hideColumnIndexesOnSmallScreen.length >
                                                            0 &&
                                                        hideColumnIndexesOnSmallScreen.includes(
                                                            colIndex
                                                        ),
                                                }"
                                                [class.no-wrap]="value.noWrap"
                                                [class.w-100]="value.fillSpace"
                                            >
                                                <div
                                                    class="d-flex align-items-center {{
                                                        value.format
                                                            ? 'table-format--' +
                                                              value.format
                                                            : ''
                                                    }}"
                                                >
                                                    <ng-container
                                                        *ngTemplateOutlet="
                                                            tableValue;
                                                            context: {
                                                                value: value,
                                                                avatarSize:
                                                                    'lg',
                                                            }
                                                        "
                                                    ></ng-container>
                                                </div>
                                            </th>
                                            <ng-template #defaultRow>
                                                <td
                                                    *ngIf="
                                                        first &&
                                                        row.toggleRowTemplate &&
                                                        !canSelectRows
                                                    "
                                                    class="px-0"
                                                >
                                                    <ng-container
                                                        *ngTemplateOutlet="
                                                            rowToggle;
                                                            context: {
                                                                row: row,
                                                            }
                                                        "
                                                    ></ng-container>
                                                </td>
                                                <td
                                                    [ngClass]="{
                                                        'd-none d-lg-table-cell':
                                                            hideColumnIndexesOnSmallScreen.length >
                                                                0 &&
                                                            hideColumnIndexesOnSmallScreen.includes(
                                                                colIndex
                                                            ),
                                                    }"
                                                    [class.no-wrap]="
                                                        value.noWrap
                                                    "
                                                    [class.w-100]="
                                                        value.fillSpace
                                                    "
                                                    [attr.data-label]="
                                                        table?.headers[colIndex]
                                                            ?.id || ''
                                                    "
                                                >
                                                    <div
                                                        class="d-flex align-items-center {{
                                                            value.format
                                                                ? 'table-format--' +
                                                                  value.format
                                                                : ''
                                                        }}"
                                                        [attr.data-cy]="
                                                            'list-cell-' +
                                                                table?.headers[
                                                                    colIndex
                                                                ]?.value || ''
                                                        "
                                                    >
                                                        <ng-container
                                                            *ngTemplateOutlet="
                                                                tableValue;
                                                                context: {
                                                                    value: value,
                                                                    avatarSize:
                                                                        'sm',
                                                                }
                                                            "
                                                        ></ng-container>
                                                    </div>
                                                </td>
                                            </ng-template>
                                        </ng-container>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </ng-container>
            </ng-container>
        </atom-async-data>

        <molecule-multiselect-banner
            *ngIf="
                !disableMultiselectBanner &&
                showMultiSelectBanner &&
                canSelectRows &&
                !canSelectOnlyOneRow
            "
            class="multiselect-banner start-0 bottom-0 position-{{
                multiselectBannerPosition
            }}"
            [actionButtons]="multiselectBannerButtons"
            [position]="multiselectBannerPosition"
            [container]="elementRef"
            [selectedCount]="selectedRows.length"
            [totalItems]="table | fn: getResultsCount : tablePaging"
            (actionButtonClicked)="onMultiselectActionButtonClicked($event)"
            (close)="closeMultiselectBanner()"
        ></molecule-multiselect-banner>
    </div>

    <div
        *ngIf="
            table?.pagingType !== PAGING_TYPE?.InfiniteScroll &&
            tablePaging?.totalPages > 1
        "
        class="d-flex justify-content-center flex-shrink-0 pt-2"
    >
        <wdx-pagination
            [page]="tablePaging?.page"
            [collectionSize]="tablePaging?.totalPages"
            [maxSize]="6"
            [scrollContainer]="scrollContainer"
            (pageChange)="onPageChanged($event)"
        ></wdx-pagination>
    </div>
</div>

<ng-template #tableValue let-value="value" let-avatarSize="avatarSize">
    <div
        class="flex-grow-1 text-truncate"
        [ngClass]="value.severity | severity: ['text']"
    >
        <ng-container
            *ngIf="
                !value.formSetup &&
                !value.routerLink &&
                !value.externalLink &&
                !value.templateRef
            "
        >
            <div
                *ngIf="value.values?.length; else singleValue"
                class="table__value"
            >
                <div
                    *ngFor="
                        let listValue of value.values;
                        let last = last;
                        let i = index
                    "
                >
                    <a
                        *ngIf="
                            value.routerLinks?.length &&
                                value.routerLinks[i].length;
                            else noValuesListRouterLinks
                        "
                        [routerLink]="value.routerLinks[i]"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                valuesListValue;
                                context: { value: listValue, last: last }
                            "
                        ></ng-container>
                    </a>
                    <ng-template #noValuesListRouterLinks>
                        <ng-container
                            *ngTemplateOutlet="
                                valuesListValue;
                                context: { value: listValue, last: last }
                            "
                        ></ng-container>
                    </ng-template>
                </div>
                <ng-template #valuesListValue let-value="value" let-last="last">
                    {{ value }}{{ last ? '' : ',' }}
                </ng-template>
            </div>
            <ng-template #singleValue>
                <div
                    *ngIf="value.value"
                    class="table__value d-flex align-items-center"
                    [attr.data-cy]="'text-info-' + value.value"
                >
                    <wdx-icon
                        *ngIf="value.icon"
                        class="me-2 text-muted align-items-center d-flex icon-container-size-md justify-content-center"
                        [iconStyle]="value.severity ? 'fas' : 'fal'"
                        [severity]="value.severity"
                        [icon]="value.icon"
                    >
                    </wdx-icon>
                    <div>
                        {{ value.value }}
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="value.formSetup">
            <a
                href="#"
                [dynamicFormSetup]="value.formSetup"
                click-prevent-default
                >{{ value.value }}</a
            >
        </ng-container>
        <ng-container *ngIf="value.routerLink">
            <a [routerLink]="value.routerLink" [innerHtml]="value.value"></a>
        </ng-container>
        <ng-container *ngIf="value.externalLink">
            <a class="text-nowrap" target="_blank" [href]="value.externalLink"
                >{{ value.value }}<i class="fal fa-external-link ms-2"></i
            ></a>
        </ng-container>
        <ng-container *ngIf="value.templateRef">
            <ng-container
                *ngTemplateOutlet="
                    value.templateRef;
                    context: { value: value.value, data: value.data }
                "
            ></ng-container>
        </ng-container>
        <div *ngIf="value.icon && !value.value" class="d-flex">
            <wdx-icon
                class="align-items-center d-flex icon-container icon-container-size-md justify-content-center no-container rounded-circle"
                [severity]="value.severity"
                [icon]="value.icon"
            >
            </wdx-icon>
        </div>
    </div>
    <div *ngIf="value.avatars" class="ms-2">
        <molecule-avatar-group
            [size]="avatarSize"
            [avatars]="value.avatars"
        ></molecule-avatar-group>
    </div>
</ng-template>

<ng-template #rowSelector let-row="row" let-index="index">
    <ng-container [ngSwitch]="rowSelectorStyle">
        <wdx-checkbox
            *ngSwitchCase="CHECKBOX_STYLE.Check"
            [checked]="selectedRows | fn: rowIsSelected : row"
            (isChecked)="onSelectRow(row, $event)"
        ></wdx-checkbox>
        <wdx-switch
            *ngSwitchCase="CHECKBOX_STYLE.Toggle"
            [toggled]="selectedRows | fn: rowIsSelected : row"
            (toggle)="onSelectRow(row, $event)"
        ></wdx-switch>
    </ng-container>
</ng-template>

<ng-template #rowToggle let-row="row">
    <wdx-icon
        class="ms-1 icon-flip-anti-clockwise-180 icon-animate align-items-center d-flex icon-container icon-container-size-md justify-content-center no-container rounded-circle"
        icon="chevron-down"
    >
    </wdx-icon>
</ng-template>

<ng-template #noData>
    <div
        *ngIf="!isLoading && !hasError"
        class="h-100 w-100 d-flex align-items-center justify-content-center"
    >
        <atom-feature-svg
            [size]="noDataSvgSize"
            [svg]="FEATURE_SVG.NoData"
            data-cy="no-record-found"
        >
            {{ SVG_TEXT_NO_RECORD_FOUND | translate }}
        </atom-feature-svg>
    </div>
</ng-template>
