import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ICellRendererParams } from '@ag-grid-community/core';
import { WdxBadgeModule } from '@wdx/shared/components/wdx-badge';

@Component({
    selector: 'wdx-ag-grid-lifecycle-cell-renderer',
    standalone: true,
    imports: [CommonModule, RouterModule, WdxBadgeModule],
    template: '<div [ngClass]="classes">{{ label }}</div>',
})
export class LifecycleRendererComponent implements ICellRendererAngularComp {
    public label?: string;
    public classes = 'badge';

    public agInit(params: ICellRendererParams<any, string>): void {
        if (params?.value) {
            this.label = params.value;
            this.setClasses(this.label);
        }
    }

    public refresh(params: ICellRendererParams<any, string>): boolean {
        if (params?.value) {
            this.label = params.value;
            this.setClasses(this.label);
        }
        return true;
    }

    private setClasses(label?: string) {
        this.classes = label
            ? `badge lifecycle-stage-${label.toLowerCase()}`
            : 'badge';
    }
}
