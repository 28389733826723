import { Injectable, inject } from '@angular/core';

import { Subject } from 'rxjs';

import {
    EntityPermissionType,
    PartyTeamMember,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { PartyToAvatarPipe } from '@wdx/clmi/utils/pipes';
import { EntityRouteService } from '@wdx/clmi/utils/services';
import {
    ActionButtonMode,
    DisplayDatePipe,
    FormInitialisationMode,
    TRANSLATION_TEAM_ADDED,
    TRANSLATION_TEAM_OWNER,
    TranslationsService,
} from '@wdx/shared/utils';
import {
    EDIT_MENU_ITEM,
    REMOVE_MENU_ITEM,
} from '../../../../../../constants/menu.constants';
import { ActionButton } from '../../../../../../models/action-button.model';
import { InfoCardData } from '../../../../../../models/info-card-data.model';

@Injectable()
export class TeamMembersTransformService {
    assignTeamMemberAsOwner$ = new Subject();
    removeTeamMember$ = new Subject();

    private entityRouteService = inject(EntityRouteService);
    private translationsService = inject(TranslationsService);

    partyToTeam(
        partyTeamMember: PartyTeamMember,
        entity: string,
        systemEntity: SystemEntity,
        permission: EntityPermissionType,
        teamMemberForm: string,
        isOwner = false,
    ): { infoCard: InfoCardData; actionButton?: ActionButton } {
        const ACTION_BTN: ActionButton = {
            mode: ActionButtonMode.DropdownButtonMenu,
            operationsSetup: {
                entityType: systemEntity,
                entityId: entity,
            },
            permission: permission,
            ignoreActions: true,
            dropdownMenu: [
                {
                    ...EDIT_MENU_ITEM,
                    formSetup: {
                        formId: teamMemberForm,
                        entityId: partyTeamMember.id,
                        initialisationMode: FormInitialisationMode.Edit,
                    },
                    permission: permission,
                },
                {
                    ...REMOVE_MENU_ITEM,
                    disabled: false,
                    callback: () =>
                        this.removeTeamMember$.next(partyTeamMember),
                    permission: permission,
                },
            ],
        };

        const result: { infoCard: InfoCardData; actionButton?: ActionButton } =
            {
                infoCard: this.setUpInfoCard(
                    partyTeamMember,
                    isOwner,
                    systemEntity,
                ),
            };

        if (partyTeamMember.id) {
            result.actionButton = ACTION_BTN;
        }

        return result;
    }

    setUpInfoCard(partyTeamMember, isOwner, systemEntity): InfoCardData {
        return {
            primaryInfo: partyTeamMember?.party?.name,
            secondaryInfo: isOwner
                ? {
                      label: this.translationsService.getTranslationByKey(
                          TRANSLATION_TEAM_OWNER,
                      ),
                  }
                : { label: partyTeamMember?.role?.label },
            tertiaryInfo:
                !isOwner && partyTeamMember?.addedOn
                    ? [
                          {
                              label: `${this.translationsService.getTranslationByKey(
                                  TRANSLATION_TEAM_ADDED,
                              )} ${new DisplayDatePipe().transform(
                                  partyTeamMember?.addedOn,
                                  { relative: true },
                              )}`,
                          },
                      ]
                    : null,
            routerLink: this.entityRouteService.routeForEntity(
                systemEntity === SystemEntity.Team
                    ? SystemEntity.User
                    : SystemEntity[partyTeamMember?.party?.partyType],
                partyTeamMember?.party?.id,
            ),
            avatars: [new PartyToAvatarPipe().transform(partyTeamMember.party)],
        };
    }
}
