import { Injectable, inject } from '@angular/core';

import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import {
    AppGroupType,
    AppStatus,
    AppType,
    Case,
    CaseType,
    DocumentTemplate,
    HeatMap,
    HeatMapEntry,
    HeatMapGroup,
    HistoryEntry,
    Process,
    SystemEntity,
    WorkItem,
    WorkItemCategorySummary,
} from '@wdx/clmi/api-models';

import { WdxDestroyClass } from '@wdx/shared/utils';
import {
    IAppMention,
    TriggerChar,
} from '../../../../shared/features/comments/shared/constants/mention.constant';
import * as rootReducer from '../../../../state/_setup/reducers';
import * as caseTypesActions from '../../../../state/case-types/case-types.actions';
import * as caseTypesSelectors from '../../../../state/case-types/case-types.selectors';
import * as casesActions from '../../../../state/cases/cases.actions';
import * as casesSelectors from '../../../../state/cases/cases.selectors';
import * as documentTemplatesActions from '../../../../state/document-templates/document-templates.actions';
import * as documentTemplatesSelectors from '../../../../state/document-templates/document-templates.selectors';
import * as processesActions from '../../../../state/processes/processes.actions';
import * as processesSelectors from '../../../../state/processes/processes.selectors';
import { CasesStoreFacade } from '../../../../state/cases/cases-store-facade.service';
import { CasePreviewGroupedApps } from '../../case/case-preview-app/case-preview-app.models';

@Injectable({
    providedIn: 'root',
})
export class CasesFacadeService extends WdxDestroyClass {
    private store$ = inject(Store<rootReducer.State>);
    private actionsSubject$ = inject(ActionsSubject);
    private casesStoreFacade = inject(CasesStoreFacade);

    casesByEntityIds: string[] = [];
    caseId: string;
    case$ = new BehaviorSubject<Case>(null);
    case: Case;
    caseIds: string[] = [];
    caseWorkIds: string[] = [];
    caseProcessIds: string[] = [];
    caseCategoryIds: string[] = [];
    caseTeamMembersIds: string[] = [];
    reloadCase$ = new Subject();

    isActiveCase$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true,
    );

    /**
     * Cases
     */
    getCases$(entityType: SystemEntity, entityId: string): Observable<Case[]> {
        return this.store$
            .select(casesSelectors.getList, { id: entityId })
            .pipe(
                tap(() => {
                    if (!this.casesByEntityIds.includes(entityId)) {
                        this.loadCases(entityType, entityId);
                        this.casesByEntityIds.push(entityId);
                    }
                }),
                filter((res) => Boolean(res)),
            );
    }

    getCasesIsLoading$(entityId: string): Observable<boolean> {
        return this.store$.select(casesSelectors.getIsLoadingList, {
            id: entityId,
        });
    }

    getCasesHasError$(entityId: string): Observable<boolean> {
        return this.store$.select(casesSelectors.getHasLoadingListError, {
            id: entityId,
        });
    }

    loadCases(entityType: SystemEntity, entityId: string): void {
        this.store$.dispatch(casesActions.getCases({ entityType, entityId }));
    }

    /**
     * Case
     */
    getCase$(caseId: string): Observable<Case> {
        return this.store$
            .select(casesSelectors.getSingle, { id: caseId })
            .pipe(
                tap(() => {
                    if (!this.caseIds.includes(caseId)) {
                        this.loadCase(caseId);
                        this.caseIds.push(caseId);
                    }
                }),
                filter((res) => Boolean(res)),
                tap((value: Case) => {
                    this.case$.next(value);
                    this.case = value;
                }),
            );
    }

    loadCase(caseId: string): void {
        this.store$.dispatch(casesActions.getCase({ caseId }));
    }

    getAppsForCase$(caseId: string): Observable<AppStatus[]> {
        return this.getCaseWork$(caseId).pipe(
            map((caseWork) =>
                caseWork.flatMap((item) => item.apps).filter(Boolean),
            ),
        );
    }

    getAppsByTypes$(
        caseId: string,
        appType: AppType[],
    ): Observable<AppStatus[]> {
        return this.getAppsForCase$(caseId).pipe(
            map((apps) =>
                apps.filter((app) => app?.type && appType.includes(app.type)),
            ),
        );
    }

    /**
     * CaseWork
     */
    getCaseWork$(caseId: string): Observable<WorkItem[]> {
        return this.store$
            .select(casesSelectors.getCaseWorkList, {
                id: caseId,
            })
            .pipe(
                tap(() => {
                    if (!this.caseWorkIds.includes(caseId)) {
                        this.loadCaseWork(caseId);
                        this.caseWorkIds.push(caseId);
                    }
                }),
                filter((res) => Boolean(res)),
            );
    }

    loadCaseWork(caseId: string): void {
        this.store$.dispatch(casesActions.getCaseWork({ caseId: caseId }));
    }

    getProcesses$(caseId: string): Observable<Process[]> {
        return this.store$
            .select(processesSelectors.getProcessesForCase, { id: caseId })
            .pipe(
                tap(() => {
                    if (!this.caseProcessIds.includes(caseId)) {
                        this.loadProcesses(caseId);
                        this.caseProcessIds.push(caseId);
                    }
                }),
                filter((res) => Boolean(res)),
            );
    }

    loadProcesses(caseId: string) {
        this.store$.dispatch(
            processesActions.getProcessesForCase({
                caseId,
            }),
        );
    }

    getCategories$(caseId: string): Observable<WorkItemCategorySummary[]> {
        return this.store$
            .select(casesSelectors.getCaseCategories, {
                id: caseId,
            })
            .pipe(
                tap(() => {
                    if (!this.caseCategoryIds.includes(caseId)) {
                        this.loadCategories(caseId);
                        this.caseCategoryIds.push(caseId);
                    }
                }),
                filter((res) => Boolean(res)),
            );
    }

    loadCategories(caseId: string) {
        this.store$.dispatch(
            casesActions.getCaseCategories({
                caseId,
            }),
        );
    }

    /**
     * CaseHistory
     */
    getCaseHistory$(caseId: string): Observable<HistoryEntry[]> {
        this.store$.dispatch(casesActions.getCaseHistory({ caseId }));
        return this.store$
            .select(casesSelectors.getCaseHistoryList, {
                id: caseId,
            })
            .pipe(filter((res) => Boolean(res)));
    }

    /**
     * Case Types
     */
    getCaseTypes$(): Observable<CaseType[]> {
        return this.store$.select(caseTypesSelectors.getList).pipe(
            tap((res) => {
                if (!res) {
                    this.store$.dispatch(
                        caseTypesActions.getAll({ isActive: true }),
                    );
                }
            }),
            filter((res) => Boolean(res)),
        );
    }

    /**
     * Document Templates
     */
    getDocumentTemplates$(): Observable<DocumentTemplate[]> {
        return this.store$
            .select(
                documentTemplatesSelectors.getDocumentTemplatesList({
                    id: SystemEntity.Case,
                }),
            )
            .pipe(
                tap((res) => {
                    if (!res) {
                        this.store$.dispatch(
                            documentTemplatesActions.getDocumentTemplates({
                                entityType: SystemEntity.Case,
                            }),
                        );
                    }
                }),
                filter((res) => Boolean(res)),
            );
    }

    /**
     * Statuses
     */
    getCompleteCaseIsLoading(caseId: string): Observable<boolean> {
        return this.store$.select(casesSelectors.completeCaseIsLoadingSingle, {
            id: caseId,
        });
    }

    checkIfIsActiveCase(caseId: string): void {
        this.getCase$(caseId)
            .pipe(
                takeUntil(this.destroyed$),
                filter((caseItem) => !!caseItem),
            )
            .subscribe((caseItem) => {
                this.isActiveCase$.next(caseItem.isActive);
            });
    }

    listenToCaseUpdatedEvent$(): Observable<any> {
        return this.actionsSubject$.pipe(
            ofType(casesActions.caseUpdated),
            filter((action: any) => action.caseId === this.caseId),
        );
    }

    getKycModeApps(caseId: string): Observable<CasePreviewGroupedApps> {
        return this.casesStoreFacade
            .kycHeatmap$(caseId)
            .pipe(
                map((heatMap) =>
                    this.sortGroups(
                        this.heatMapToCasePreviewGroupedApps(heatMap, caseId),
                    ),
                ),
            );
    }

    getApprovalModeApps(caseId: string): Observable<CasePreviewGroupedApps> {
        return this.casesStoreFacade
            .caseHeatmap$(caseId)
            .pipe(
                map((heatMap) =>
                    this.sortGroups(
                        this.heatMapToCasePreviewGroupedApps(heatMap, caseId),
                    ),
                ),
            );
    }

    // Sort groups: PartyRole > Client > ClientProduct
    public sortGroups(
        groupedApps: CasePreviewGroupedApps,
    ): CasePreviewGroupedApps {
        const isPartyRole = (type: AppGroupType) =>
            ![AppGroupType.Client, AppGroupType.Products].includes(type);
        groupedApps.groups.sort((a, b) => {
            if (a.type === b.type) {
                return a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1;
            }
            if (isPartyRole(a.type)) {
                return -1;
            }
            if (isPartyRole(b.type)) {
                return 1;
            }
            if (a.type === AppGroupType.Client) {
                return -1;
            }
            return 1;
        });

        groupedApps.allApps = groupedApps.groups.reduce(
            (prev, curr) => [...prev, ...curr.apps] as IAppMention[],
            [] as IAppMention[],
        );

        return groupedApps;
    }

    private heatMapToCasePreviewGroupedApps(
        heatMap: HeatMap,
        caseId: string,
    ): CasePreviewGroupedApps {
        const allApps = heatMap.groups.flatMap((group) =>
            group.apps.flatMap((app) =>
                app?.apps?.length
                    ? app.apps.map((childApp) =>
                          this.heatMapToIAppMention(
                              childApp,
                              caseId,
                              group,
                              group.type,
                          ),
                      )
                    : this.heatMapToIAppMention(app, caseId, group, group.type),
            ),
        );
        const groups = [];
        heatMap.groups.forEach((group) => {
            const appGroups =
                group.apps?.[0]?.type === AppType.Group ? group.apps : [group];
            appGroups.forEach((appGroup) =>
                groups.push({
                    id: appGroup.name,
                    name: this.getName(appGroup),
                    type: appGroup.type,
                    apps: appGroup.apps.map((childApp) =>
                        this.heatMapToIAppMention(
                            childApp,
                            caseId,
                            appGroup,
                            group.type,
                        ),
                    ),
                }),
            );
        });

        return { allApps, groups };
    }

    private heatMapToIAppMention(
        app: HeatMapEntry,
        caseId: string,
        group: HeatMapGroup,
        type: AppGroupType,
    ): IAppMention {
        return {
            app: app,
            caseId,
            displayName: app.name,
            regarding: [
                {
                    name: group.name,
                    ...(app.party || app.partyRole),
                    type: group.type as string as SystemEntity,
                },
            ],
            workItemId: caseId,
            name: app.name,
            triggerChar: TriggerChar.Hash,
            type,
        } as IAppMention;
    }

    private getName(group: HeatMapGroup) {
        const name =
            group.apps?.[0]?.party?.name ||
            group.apps?.[0]?.partyRole?.name ||
            group.apps?.[0]?.apps?.[0]?.party?.name ||
            group.apps?.[0]?.apps?.[0]?.partyRole?.name ||
            group.name;
        const description =
            group?.description ||
            group.apps?.[0]?.description ||
            group.apps?.[0]?.apps?.[0]?.description;
        const suffix = description ? ` (${description})` : '';
        return `${name}${suffix}`;
    }
}
