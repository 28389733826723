<div class="px-4" data-cy="dlg-confirm">
    <h5 class="text-center mt-5 mb-4" data-cy="lbl-confirm-title">
        {{
            data?.title || (dialogMode | fn: getTitle)
                | translateTokenisedString
        }}
    </h5>
    <div
        class="d-flex align-items-center justify-content-center pb-3 mb-3 border-bottom"
    >
        <div class="dialog-body m-3">
            <ng-container [ngSwitch]="dialogMode">
                <ng-container *ngSwitchCase="DIALOG_MODE.CancelCase">
                    {{ CANCEL_CASE | translateTokenisedString }}
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.CancelActivity">
                    {{ CANCEL_ACTIVITY | translateTokenisedString }}
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.RemoveTeamMember">
                    <p
                        [innerHTML]="
                            'DIALOG_REMOVE_TEAM_MEMBER'
                                | translateTokenisedString
                                    : {
                                          teamMember: data?.name,
                                      }
                        "
                    ></p>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteOpportunity">
                    <p
                        [innerHTML]="
                            'DIALOG_DELETE_OPPORTUNITY'
                                | translateTokenisedString
                                    : {
                                          dataName: data.name,
                                      }
                        "
                    ></p>
                </ng-container>
                <ng-container
                    *ngSwitchCase="DIALOG_MODE.DeleteDocumentTemplate"
                >
                    <ng-container
                        *ngTemplateOutlet="deleteDialog"
                    ></ng-container>
                </ng-container>
                <ng-container
                    *ngSwitchCase="DIALOG_MODE.DeleteDocumentRequirement"
                >
                    {{ DELETE_DOC_REQUIREMENT | translateTokenisedString }}
                </ng-container>
                <ng-container
                    *ngSwitchCase="DIALOG_MODE.DeleteDocumentRequirementType"
                >
                    <ng-container
                        *ngTemplateOutlet="deleteDialog"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteBatchList">
                    <p
                        [innerHTML]="
                            DELETE_BATCH_LIST
                                | translateTokenisedString
                                    : {
                                          dataName: data?.name,
                                      }
                        "
                    ></p>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteBatchMessageRow">
                    <p
                        [innerHTML]="
                            DELETE_BATCH_MESSAGE_ROW
                                | translateTokenisedString
                                    : {
                                          dataName: data?.name,
                                      }
                        "
                    ></p>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteView">
                    <p
                        [innerHTML]="
                            DELETE_VIEW
                                | translateTokenisedString
                                    : {
                                          dataName: data?.name,
                                      }
                        "
                    ></p>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.ReplayMessageBatch">
                    <p
                        [innerHTML]="
                            REPLAY_MESSAGE_BATCH
                                | translateTokenisedString
                                    : {
                                          dataName: data?.name,
                                      }
                        "
                    ></p>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteMultipleMessage">
                    {{ DELETE_MULTIPLE_MESSAGE | translateTokenisedString }}
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.ReplayMultipleMessage">
                    {{ REPLAY_MULTIPLE_MESSAGE | translateTokenisedString }}
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteDocument">
                    <p
                        [innerHTML]="
                            DIALOG_DELETE_DOC
                                | translateTokenisedString
                                    : {
                                          dataName: data?.name,
                                      }
                        "
                    ></p>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteDashboard">
                    {{ DELETE_DASHBOARD | translateTokenisedString }}
                </ng-container>
                <ng-container
                    *ngSwitchCase="DIALOG_MODE.RunPepAndSanctionsCheck"
                >
                    {{ RUN_PEP_SANCTIONS_CHECK | translateTokenisedString }}
                </ng-container>
                <ng-container
                    *ngSwitchCase="DIALOG_MODE.CancelDocumentRequirement"
                >
                    {{ CANCEL_DOC_REQUIREMENT | translateTokenisedString }}
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.Delete">
                    <ng-container
                        *ngTemplateOutlet="deleteDialog"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.ShareAppWithClient">
                    <p
                        [innerHTML]="
                            SHARE_APP_WITH_CLIENT
                                | translateTokenisedString
                                    : {
                                          appName: data.appName,
                                          clientName: data.clientName,
                                      }
                        "
                    ></p>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.ReturnAppToClient">
                    {{ RETURN_APP_TO_CLIENT | translateTokenisedString }}
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteDocumentType">
                    <ng-container
                        *ngTemplateOutlet="deleteDialog"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="DIALOG_MODE.DeleteClientRole">
                    {{
                        TRANSLATION_DIALOG_REMOVE_CLIENT_ROLE
                            | translateTokenisedString
                    }}
                </ng-container>
                <ng-container
                    *ngSwitchCase="DIALOG_MODE.DeleteActiveClientRole"
                >
                    {{ DELETE_ACTIVE_CLIENT_ROLE | translateTokenisedString }}
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
        <div class="dialog-footer mt-4 mb-5">
            <button class="btn" data-cy="btn-no" (click)="dismiss()">
                {{ NO_BTN | translateTokenisedString }}
            </button>
            <button
                class="btn btn-danger ms-4"
                data-cy="btn-yes"
                (click)="confirm()"
            >
                {{ YES_BTN | translateTokenisedString }}
            </button>
        </div>
    </div>

    <ng-template #deleteDialog>
        <p
            [innerHTML]="
                GENERAL_DELETE_MESSAGE
                    | translateTokenisedString
                        : {
                              dataName: data?.name,
                          }
            "
        ></p>
    </ng-template>
</div>
