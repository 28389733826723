import { createSelector } from '@ngrx/store';
import { SystemEntity } from '@wdx/clmi/api-models';
import { GLOBAL_STATE_INDEX_ID, OperationsSetup } from '@wdx/shared/utils';
import * as operationsReducer from './operations.reducer';

export const getState = (state: {
    operations: operationsReducer.State;
}): operationsReducer.State => state.operations;

export const operationsForEntityIsLoadingSingle = createSelector(
    getState,
    (state: operationsReducer.State, props: { entityType: SystemEntity }) =>
        state.operations[props.entityType]?.[GLOBAL_STATE_INDEX_ID]
            ?.isLoadingSingle,
);
export const operationsForEntityHasLoadingSingleError = createSelector(
    getState,
    (state: operationsReducer.State, props: { entityType: SystemEntity }) =>
        state.operations[props.entityType]?.[GLOBAL_STATE_INDEX_ID]
            ?.hasLoadingSingleError,
);

export const getOperationsForEntity = createSelector(
    getState,
    (state: operationsReducer.State, props: { entityType: SystemEntity }) =>
        state.operations[props.entityType]?.[GLOBAL_STATE_INDEX_ID]?.single,
);
export const getPermissionsForEntity = createSelector(
    getState,
    (state: operationsReducer.State, props: { entityType: SystemEntity }) =>
        state.operations[props.entityType]?.[GLOBAL_STATE_INDEX_ID]?.single
            ?.permissions,
);
export const getActionsForEntity = createSelector(
    getState,
    (state: operationsReducer.State, props: { entityType: SystemEntity }) =>
        state.operations[props.entityType]?.[GLOBAL_STATE_INDEX_ID]?.single
            ?.actions,
);

export const operationsForIdIsLoadingSingle = createSelector(
    getState,
    (state: operationsReducer.State, props: OperationsSetup) =>
        state.operations[props.entityType]?.[props.entityId]?.isLoadingSingle,
);
export const operationsForIdHasLoadingSingleError = createSelector(
    getState,
    (state: operationsReducer.State, props: OperationsSetup) =>
        state.operations[props.entityType]?.[props.entityId]
            ?.hasLoadingSingleError,
);

export const getOperationsForId = createSelector(
    getState,
    (state: operationsReducer.State, props: OperationsSetup) =>
        state.operations[props.entityType]?.[props.entityId]?.single,
);

export const getPermissionsForId = createSelector(
    getState,
    (state: operationsReducer.State, props: OperationsSetup) =>
        state.operations[props.entityType]?.[props.entityId]?.single
            ?.permissions,
);

export const getActionsForId = createSelector(
    getState,
    (state: operationsReducer.State, props: OperationsSetup) =>
        state.operations[props.entityType]?.[props.entityId]?.single?.actions,
);

export const getLinksForId = (props: OperationsSetup) =>
    createSelector(
        getState,
        (state: operationsReducer.State) =>
            state.operations[props.entityType]?.[props.entityId]?.single?.links,
    );

export const getMessagesForId = (props: OperationsSetup) =>
    createSelector(
        getState,
        (state: operationsReducer.State) =>
            state.operations[props.entityType]?.[props.entityId]?.single
                ?.messages,
    );

export const getStatus = createSelector(
    getState,
    (state: operationsReducer.State) => state.status,
);

export const getIsPatching = createSelector(
    getState,
    (state: operationsReducer.State) => state.isPatching,
);
