import { Component, HostBinding, Input } from '@angular/core';
import { Avatar, AvatarDetailPopup } from '../models';

@Component({
    selector: 'wdx-avatar-popup-content',
    templateUrl: './wdx-avatar-popup-content.component.html',
})
export class AvatarPopupContentComponent {
    @HostBinding('class') class = 'd-flex align-items-center p-2';

    @Input() avatar?: Avatar;
    @Input() content?: AvatarDetailPopup;
}
