import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    CubeResult,
    CurrencyValue,
    FilterDefinition,
    Query,
    QueryExpression,
    SystemEntity,
} from '@wdx/clmi/api-models';
import { QueryType } from '@wdx/clmi/api-services/models';
import {
    QueryApiService,
    ViewApiService,
} from '@wdx/clmi/api-services/services';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, tap } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    CubeConfig,
    CubeConfigSeries,
    CubeXAxis,
    RollupConfig,
} from '../../models';

/**
 * A singleton service provding cached observables providing data across all chart components and services.
 */
@Injectable({
    providedIn: 'root',
})
export class ChartDataService {
    private queryApiService = inject(QueryApiService);
    private viewApiService = inject(ViewApiService);

    private viewQueryCache = new Map<string, Observable<Query>>();
    private queryDefinitionCache = new Map<
        string,
        Observable<FilterDefinition>
    >();
    private cubeResultsCache = new Map<string, Observable<CubeResult[]>>();
    private rollupResultCache = new Map<
        string,
        Observable<number | CurrencyValue>
    >();

    private refresh$ = new BehaviorSubject<void>(undefined);
    private _lastRefreshDate$ = new BehaviorSubject<Date | null>(null);
    public lastRefreshDate$!: Observable<Date | null>;

    private readonly THROTTLE_TIME = 1000;

    constructor() {
        this.lastRefreshDate$ = this._lastRefreshDate$.pipe(
            debounceTime(this.THROTTLE_TIME),
            takeUntilDestroyed(),
        );
    }

    getViewQuery$(entityType: SystemEntity, viewId: string): Observable<Query> {
        const cacheKey = this.getViewQueryCacheKey(entityType, viewId);

        let result$ = this.viewQueryCache.get(cacheKey);

        if (!result$) {
            result$ = viewId
                ? (this.viewApiService
                      .getSingleForEntityType(viewId, entityType)
                      .pipe(
                          map((view) => view.filter),
                          map((filter) => {
                              if (
                                  filter?.expressions &&
                                  Array.isArray(filter.expressions)
                              ) {
                                  const expressions: QueryExpression[] =
                                      filter.expressions.map((expression) => {
                                          const values = Array.isArray(
                                              expression?.values?.[0],
                                          )
                                              ? (expression as QueryExpression)
                                                    ?.values?.[0]
                                              : expression.values;
                                          return { ...expression, values };
                                      });
                                  return {
                                      ...filter,
                                      expressions,
                                  };
                              }
                              return filter as any;
                          }),
                          shareReplay(1),
                      ) as Observable<Query>)
                : of({ expressions: [] });

            this.viewQueryCache.set(cacheKey, result$);
        }

        return result$;
    }

    getQueryDefinition$(queryType: QueryType): Observable<FilterDefinition> {
        let result$ = this.queryDefinitionCache.get(queryType);

        if (!result$) {
            result$ = this.queryApiService
                .getQueryDefinition(queryType)
                .pipe(shareReplay(1));

            this.queryDefinitionCache.set(queryType, result$);
        }
        return result$;
    }

    getRollupResult$(
        rollupConfig: RollupConfig,
        query: Query,
    ): Observable<number | CurrencyValue> {
        const cacheKey = this.getRollupCacheKey(rollupConfig);
        let result$ = this.rollupResultCache.get(cacheKey);

        if (!result$) {
            const expressions = query.expressions;
            const rollupType = rollupConfig.rollupType;
            const rollupColumn = rollupConfig.rollupColumn?.name;

            result$ = this.refresh$.pipe(
                switchMap(() =>
                    this.queryApiService.getRollup(rollupConfig.entityType, {
                        ...(expressions && { expressions }),
                        rollupType,
                        ...(rollupColumn && { rollupColumn }),
                    }),
                ),
                tap(() => this.setLastRefreshDate()),
                shareReplay(1),
            );

            this.rollupResultCache.set(cacheKey, result$);
        }

        return result$;
    }

    getCubeResults$(
        cubeConfig: CubeConfig,
        query: Query,
    ): Observable<CubeResult[][]> {
        const cubeObservables = cubeConfig.series.map((seriesItem) => {
            const cacheKey = this.getCubeResultsCacheKey(
                cubeConfig,
                seriesItem,
            );

            let result$ = this.cubeResultsCache.get(cacheKey);

            const seriesGroupName = seriesItem.group?.name;
            const xAxisGroupName = cubeConfig.xAxis?.group?.name;

            if (!result$) {
                const groups = [seriesGroupName];
                if (xAxisGroupName && seriesGroupName !== xAxisGroupName) {
                    groups.push(xAxisGroupName);
                }

                result$ = this.refresh$.pipe(
                    switchMap(() =>
                        this.queryApiService.getCube(
                            cubeConfig.entityType,
                            this.getQueryParams(
                                query,
                                groups,
                                seriesItem,
                                cubeConfig.xAxis,
                            ),
                        ),
                    ),
                    map((cubeResults) => cubeResults),
                    tap(() => this.setLastRefreshDate()),
                    shareReplay(1),
                );
                this.cubeResultsCache.set(cacheKey, result$);
            }
            return result$;
        });

        return combineLatest(cubeObservables);
    }

    getQueryParams(
        query: Query,
        groups: string[],
        seriesItem: CubeConfigSeries,
        xAxis: CubeXAxis,
    ) {
        const expressions = query?.expressions || [];
        const rollupType = seriesItem.rollupType;
        const rollupColumn = seriesItem.rollupColumn?.name;
        const interval = seriesItem.interval || xAxis.interval;
        return {
            ...(expressions && { expressions }),
            groups,
            rollupType,
            ...(interval && {
                interval,
            }),
            ...(rollupColumn && {
                rollupColumn,
            }),
        };
    }

    getViewQueryCacheKey(entityType: SystemEntity, viewId: string) {
        return [entityType, viewId]
            .filter((fragment) => Boolean(fragment))
            .join('-');
    }

    getRollupCacheKey(rollupConfig: RollupConfig): string {
        return [
            rollupConfig.entityType,
            rollupConfig.viewId,
            rollupConfig.viewQuery
                ? JSON.stringify(rollupConfig.viewQuery)
                : false,
            rollupConfig.rollupType,
            rollupConfig.rollupColumn?.name,
        ]
            .filter((fragment) => Boolean(fragment))
            .join('-');
    }

    getCubeResultsCacheKey(
        cubeConfig: CubeConfig,
        seriesItem: CubeConfigSeries,
    ): string {
        return [
            cubeConfig.title,
            cubeConfig.entityType,
            cubeConfig.viewId,
            cubeConfig.viewQuery ? JSON.stringify(cubeConfig.viewQuery) : false,
            cubeConfig.xAxis?.group?.name,
            seriesItem.group?.name,
            seriesItem.rollupType,
            seriesItem.interval,
            seriesItem.rollupColumn?.name,
        ]
            .filter((fragment) => Boolean(fragment))
            .join('-');
    }

    public refresh(): void {
        this.refresh$.next();
    }

    private setLastRefreshDate(): void {
        this._lastRefreshDate$.next(new Date());
    }
}
